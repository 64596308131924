<template>
  <div ref="articleScroll" class="panel-scroll">
    <div class="panel-body">
      <div class="panel-body-top">
        <h6 class="mb-3">Find Articles</h6>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="search-addon1">
              <svg-icon name="search" />
            </span>
          </div>
          <input
            type="text"
            aria-label="search"
            aria-describedby="search-addon1"
            v-model="search"
            @keyup.enter="refetchArticles"
            @change="handleArticleKeywordChange"
            autocomplete="off"
            placeholder="Enter a keyword and hit enter"
          />
        </div>

        <div v-if="hasArticles">
          <DragAndDropIndicator label="articles" />
        </div>
      </div>

      <div class="mt-4">
        <div v-if="hasArticles" class="">
          <drag
            v-for="article in articleSearch.articles"
            :transfer-data="{ type: 'article', data: article }"
            :key="article.id"
            class="fab-article-item"
            @dragstart="handleDragStart"
            @dragend="handleDragEnd"
          >
            <lazy-background-image :src="article.image" class="article-img" />
            <a href="#" class="fab-article-title" @click.prevent="read(article)">
              {{ descape(article.title) }}
            </a>
            <div class="article-excerpt" v-html="article.summary"></div>
            <div class="my-2 text-success">{{ article.domain }}</div>

            <div class="fab-article-footer">
              <div class="article-stats">
                <div :id="`article-${article.id}`" class="article-stats-item">
                  <div class="article-stats-name">Engagement</div>
                  <div class="article-stats-value">{{ numFormat(article.engagement) }}</div>
                </div>
                <SharesPopover :target="`article-${article.id}`" :article="article" />
                <div class="article-stats-item">
                  <div class="article-stats-name">Trend score</div>
                  <div class="article-stats-value">{{ numFormat(article.score) }}</div>
                </div>
                <div v-b-tooltip :title="capitalize(article.sentiment)" class="article-stats-item">
                  <div class="article-stats-name">Sentiment</div>
                  <SentimentIcon :sentiment="article.sentiment" />
                </div>
              </div>
            </div>
          </drag>
        </div>
        <div v-else-if="!$apollo.queries.articleSearch.loading && !hasArticles" class="text-center mt-4">
          Nothing found! <br />
          Try another keyword.
        </div>
        <div v-if="$apollo.queries.articleSearch.loading" class="text-center"><EditorFabsArticlesSkeleton /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { decode } from 'he';
import { capitalize } from 'lodash';
import { Drag } from 'vue-drag-drop';

import { numFormat } from '~/utils/helpers';
import { ARTICLE_SEARCH_QUERY } from '~/graphql/queries';
import EditorFabsArticlesSkeleton from './EditorFabsArticlesSkeleton';

import LazyBackgroundImage from '~/components/LazyBackgroundImage';
import SharesPopover from '~/components/Articles/SharesPopover';
import SentimentIcon from '~/components/Articles/SentimentIcon';
import DragAndDropIndicator from '~/components/General/DragAndDropIndicator';

export default {
  name: 'editor-fabs-articles-panel',

  components: {
    Drag,
    DragAndDropIndicator,
    EditorFabsArticlesSkeleton,
    LazyBackgroundImage,
    SharesPopover,
    SentimentIcon,
  },

  data() {
    return {
      articleSearch: {},
      page: 1,
      articleKeywords: '',
      search: '',
      apiRoot: process.env.VUE_APP_API_ROOT,
    };
  },

  apollo: {
    articleSearch: {
      query: ARTICLE_SEARCH_QUERY,
      variables() {
        return {
          keywords: this.computedArticleKeywords
            .split(',')
            .map(k => k.trim())
            .filter(k => k !== ''),
          limit: 20,
        };
      },
    },
  },

  computed: {
    computedArticleKeywords() {
      return this.articleKeywords;
    },
    hasArticles() {
      return this.articleSearch && this.articleSearch.articles && this.articleSearch.articles.length > 0;
    },
  },

  methods: {
    numFormat,
    capitalize,

    read(article) {
      this.$store.dispatch('article/invoke', article);

      this.$router.push({ query: { ...this.$route.query, read: article.id } });
    },

    descape(text) {
      return decode(text);
    },

    handleDragStart() {
      this.$store.dispatch('postEditor/updateIsDragging', true);
    },

    handleDragEnd({ data }, e) {
      this.$emit('dragged', { data, x: e.x, y: e.y });
      this.$store.dispatch('postEditor/updateIsDragging', false);
    },

    handleArticleKeywordChange() {
      this.articleKeywords = this.search;
    },

    refetchArticles(event) {
      event.preventDefault();

      this.articleSearch.articles = [];

      this.$apollo.queries.articleSearch.refetch({
        keywords: this.computedArticleKeywords,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.fab-article-item {
  box-shadow: 1px 2px 5px $gray-200;
  padding: 4px;
  cursor: move;
  margin-bottom: 20px;
  border: 1px solid transparent;
  background-color: $white;
  color: $primary;

  &:hover {
    border: 1px solid $primary;
    border-radius: 4px;
  }

  .article-excerpt {
    .highlighted {
      display: inline-block;
      background-color: rgba($primary, 0.4);
      padding: 0 2px;
    }
  }

  .article-img {
    height: 150px;
    background-size: cover;
    border: 1px solid $gray-200;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .fab-article-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: $gray-600;
  }

  .fab-article-footer {
    padding: 10px 0 15px;
    .article-stats {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $gray-200;
      padding-top: 10px;

      .article-stats-item {
        text-align: center;
        border-right: 1px solid $gray-200;
        width: 100%;

        &:last-of-type {
          border-right: 0;
        }

        .article-stats-name {
          font-size: 0.7rem;
          margin-bottom: 2px;
        }
        .article-stats-value {
          font-size: 0.9rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
