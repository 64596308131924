<template>
  <!-- edit -->
  <div class="fabs editor-fabs">
    <div class="images button-wrap" :class="{ open: activePanel == 'images' }">
      <button @click="openPanel('images')" id="imageBtn" class="link-button fab-button">
        <svg-icon class="button-icon" name="image" />
        Images
      </button>
      <b-tooltip v-if="activePanel == null" triggers="hover" container="imageBtn" target="imageBtn" placement="right">
        Images
      </b-tooltip>
      <simplebar v-show="activePanel == 'images'" class="panel-board" v-click-outside="hidePanel">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Images</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <EditorFabImagesPanel @dragged="handleImageDrag" />
      </simplebar>
    </div>
    <div class="uploads button-wrap" :class="{ open: activePanel == 'uploads' }">
      <button @click="openPanel('uploads')" id="uploadsBtn" class="uploads-button fab-button">
        <svg-icon class="button-icon" name="upload" />
        Uploads
      </button>
      <b-tooltip
        v-if="activePanel == null"
        triggers="hover"
        container="uploadsBtn"
        target="uploadsBtn"
        placement="right"
        >Uploads</b-tooltip
      >
      <simplebar v-show="activePanel == 'uploads'" class="panel-board" v-click-outside="hidePanel">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Uploads</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <EditorFabUploadsPanel :allowVideos="allowVideos" @dragged="handleImageDrag" @insert="handleUploadInsert" />
      </simplebar>
    </div>
    <div class="gifs button-wrap" :class="{ open: activePanel == 'gifs' }">
      <button @click="openPanel('gifs')" id="gifBtn" class="gif-button fab-button">
        <svg-icon class="button-icon" name="gif" />
        GIF
      </button>
      <b-tooltip v-if="activePanel == null" triggers="hover" container="gifBtn" target="gifBtn" placement="right"
        >Gifs</b-tooltip
      >
      <simplebar v-show="activePanel == 'gifs'" class="panel-board" v-click-outside="hidePanel">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Gifs</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <EditorFabGifsPanel @dragged="handleImageDrag" />
      </simplebar>
    </div>
    <div class="memes button-wrap" :class="{ open: activePanel == 'memes' }">
      <button @click="openPanel('memes')" id="memeBtn" class="meme-button fab-button">
        <svg-icon class="button-icon" name="laugh" />
        Memes
      </button>
      <b-tooltip v-if="activePanel == null" triggers="hover" container="memeBtn" target="memeBtn" placement="right"
        >Memes</b-tooltip
      >
      <simplebar v-show="activePanel == 'memes'" class="panel-board" v-click-outside="hidePanel">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Memes (Imgur)</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <EditorFabMemesPanel @dragged="handleMemeDrag" @insert="handleInsertMeme" />
      </simplebar>
    </div>
    <div class="quotes button-wrap" :class="{ open: activePanel == 'quotes' }">
      <button @click="openPanel('quotes')" id="quoteBtn" class="image-button fab-button">
        <svg-icon class="button-icon" name="quote" />
        Quotes
      </button>
      <b-tooltip v-if="activePanel == null" triggers="hover" container="quoteBtn" target="quoteBtn" placement="right"
        >Quotes</b-tooltip
      >
      <simplebar v-show="activePanel == 'quotes'" class="panel-board" v-click-outside="hidePanel">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Quotes</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <EditorFabQuotesPanel @dragged="handleQuoteDrag" />
      </simplebar>
    </div>
    <div class="articles button-wrap" :class="{ open: activePanel == 'articles' }">
      <button @click="openPanel('articles')" id="linkBtn" class="articles-button fab-button">
        <svg-icon class="button-icon" name="globe" />
        Articles
      </button>
      <b-tooltip v-if="activePanel == null" triggers="hover" container="linkBtn" target="linkBtn" placement="right"
        >Articles</b-tooltip
      >
      <simplebar v-show="activePanel == 'articles'" class="panel-board" v-click-outside="hidePanel">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Articles</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <EditorFabArticlesPanel @dragged="handleArticleDrag" />
      </simplebar>
    </div>
    <div class="videos button-wrap" :class="{ open: activePanel == 'videos' }">
      <button @click="openPanel('videos')" id="videoBtn" class="videos-button fab-button">
        <svg-icon class="button-icon" name="video-player" />
        Videos
        <!-- <fab-curve-icon v-if="activePanel == 'videos'" class="open-icon" /> -->
      </button>
      <b-tooltip v-if="activePanel == null" triggers="hover" container="videoBtn" target="videoBtn" placement="right"
        >Videos</b-tooltip
      >
      <simplebar v-show="activePanel == 'videos'" class="panel-board" v-click-outside="hidePanel">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Videos</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <EditorFabVideosPanel @dragged="handleVideoDrag" @insert="handleVideoLinkInsert" />
      </simplebar>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import EditorFabGifsPanel from './EditorFabGifsPanel';
import EditorFabMemesPanel from './EditorFabMemesPanel';
import EditorFabQuotesPanel from './EditorFabQuotesPanel';
import EditorFabImagesPanel from './EditorFabImagesPanel';
import EditorFabVideosPanel from './EditorFabVideosPanel';
import EditorFabUploadsPanel from './EditorFabUploadsPanel';
import EditorFabArticlesPanel from './EditorFabArticlesPanel';

export default {
  name: 'editor-fabs',

  components: {
    simplebar,
    EditorFabGifsPanel,
    EditorFabMemesPanel,
    EditorFabQuotesPanel,
    EditorFabImagesPanel,
    EditorFabVideosPanel,
    EditorFabUploadsPanel,
    EditorFabArticlesPanel,
  },

  props: {
    allowVideos: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('postEditor', {
      activePanel: state => state.openFabPanel,
    }),
  },

  methods: {
    openPanel(panel) {
      this.$store.dispatch('postEditor/updateOpenFabPanel', panel);
    },

    closeAllPanels() {
      this.$store.dispatch('postEditor/updateOpenFabPanel', null);
    },

    hidePanel(event) {
      const isClickOnFabButton = event.target.closest('.fab-button');
      const isClickOnPanel = event.target.closest('.panel-board');
      const isClickOnFabModal = event.target.closest('.persist-panel');

      if (!isClickOnFabButton && !isClickOnPanel && !isClickOnFabModal) {
        this.closeAllPanels();
      }
    },

    handleImageDrag(params) {
      this.$emit('draggedImage', params);
      this.closeAllPanels();
    },

    handleMemeDrag(params) {
      this.$emit('draggedMeme', params);
      this.closeAllPanels();
    },

    handleUploadInsert(upload) {
      this.$emit('insertUpload', upload);
      this.closeAllPanels();
    },

    handleVideoLinkInsert(video) {
      this.$emit('insertVideoLink', video);

      this.closeAllPanels();
    },

    handleInsertMeme(meme) {
      this.$emit('insertMeme', meme);

      this.closeAllPanels();
    },

    handleQuoteDrag(quote) {
      this.$emit('draggedQuote', quote);

      this.closeAllPanels();
    },

    handleArticleDrag(article) {
      this.$emit('draggedArticle', article);

      this.closeAllPanels();
    },

    handleVideoDrag(video) {
      this.$emit('draggedVideo', video);

      this.closeAllPanels();
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.fabs {
  &.editor-fabs {
    left: 0;
    top: 150px;
    padding: 15px 0;
    background: $primary;
    border-radius: 0 10px 10px 0;

    .button-wrap {
      padding: 6px 20px;
      border-radius: 0px;
      margin: 2px auto;

      .panel-board {
        position: absolute;
        z-index: 10;
        background: $primary;
        box-sizing: content-box;
        width: 400px;
        height: 100%;
        min-height: 480px;
        max-height: 500px;
        left: 99px;
        top: 0;
        border-radius: 6px;
        box-shadow: 0 2px 16px 2px rgba(22, 45, 61, 0.16);
        border-radius: 10px;

        .simplebar-scrollbar {
          width: 5px;
          opacity: 0;
        }
        .simplebar-scrollbar:before {
          border-radius: 5px;
          background: darken($primary, 30);
        }

        .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
          opacity: 1;
        }
        .panel-header {
          padding: 16px;
          color: $secondary;
          &_title {
            padding: 6px 12px;
            text-transform: uppercase;
            background: #2d6a4f;
            color: #d8f3dc;
            border: 1px solid #b7e4c7;
            border-radius: 10px;
          }
          &_button {
            padding: 0 !important;
            svg {
              fill: $secondary;
            }
          }
        }

        .panel-scroll {
          max-height: 445px;
          overflow-y: auto;
          overflow-x: hidden;
        }

        .panel-body {
          color: #d8f3dc;
          padding: 10px 20px;
        }
        .panel-body-top {
          padding: 20px;
          text-align: left;
          color: $white;
          border: 1px solid #b7e4c7;
          border-radius: 10px;
          .input-group {
            color: $primary !important;
            border-radius: 0.3rem !important;
            background-color: #ffffff !important;
            input {
              font-size: 0.9rem;
              height: 40px;
              width: 85%;
              &::placeholder {
                color: $gray-500;
              }
            }
          }
          .input-group-text {
            svg {
              fill: #b7e4c7;
            }
          }
          input,
          .input-group-prepend,
          .input-group-text {
            background-color: transparent;
            border: none;
            outline: none;
            &:hover,
            &:active,
            &:focus {
              background-color: transparent;
              border: none;
              outline: none;
            }
          }
        }
      }
      &:hover {
        background: #2d6a4f;
        border-radius: 0px;
        padding-top: 1rem;
        transition: 0.2s all;
        .fab-button {
          color: $white;
          fill: $white;
        }
      }

      .fab-button {
        & svg {
          margin-bottom: 0.2rem;
        }
        .tooltip {
          margin-left: 10px;

          .tooltip-inner {
            border-radius: $rounded-pill;
            padding: 6px 15px;

            &:empty {
              display: none;
            }
          }

          .arrow {
            display: none;
          }
        }
      }

      &.open {
        background: #2d6a4f;
        border-radius: 10px 0 0 10px;
        .fab-button {
          color: $white;
          .button-icon {
            fill: $white;
          }

          &:after {
            content: '';
            position: absolute;
            width: 14px;
            height: 68px;
            right: -29px;
            top: -6px;
            z-index: 13;
            background: $success;
            border-radius: 6px 0 0 6px;
          }
        }
      }
    }
  }
}
</style>
