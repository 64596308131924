<template>
  <div ref="memesScroll" class="panel-scroll meme-panel">
    <div class="panel-body">
      <div class="panel-body-top">
        <h6 class="mb-3">Find Memes</h6>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="search-addon1">
              <svg-icon name="search" />
            </span>
          </div>
          <input
            type="text"
            aria-label="search"
            aria-describedby="search-addon1"
            v-model="search"
            @keyup.enter="refetchMemes"
            @change="handleMemeKeywordChange"
            autocomplete="off"
            placeholder="Enter a keyword and hit enter"
          />
        </div>

        <div v-if="hasMemes">
          <DragAndDropIndicator label="memes" />
        </div>
      </div>

      <div class="images-wrap mt-4">
        <h6 v-if="!memeKeywords">Viral Memes</h6>
        <h6 v-else>Search results for "{{ memeKeywords }}"</h6>
        <div v-if="hasMemes" class="images-grid">
          <drag
            v-for="meme in memeSearch.memes"
            :key="meme.id"
            :transfer-data="{ type: 'meme', data: meme }"
            class="meme-item"
            :class="{ image: meme.type === 'image' || meme.type === 'gif', video: meme.type === 'video' }"
            @dragstart="handleDragStart"
            @dragend="handleDragEnd"
            @click.native="handleMemeClick(meme)"
          >
            <b-badge v-if="meme.isAlbum && meme.imagesCount > 1" variant="primary" class="image-count shadow-sm">
              {{ meme.imagesCount }}
            </b-badge>
            <div
              v-if="meme.type === 'image' || meme.type === 'gif'"
              :style="{ backgroundImage: `url(${meme.url})` }"
              class="meme-img"
            ></div>
            <video v-else-if="meme.type === 'video'" loop class="meme-video" muted>
              <source type="video/mp4" :src="meme.url" />
            </video>
            <b-badge v-if="meme.type === 'video'" variant="danger" class="meme-type">
              <svg-icon name="film" class="mt-0" />
            </b-badge>
            <div class="meme-caption">{{ meme.title }}</div>
            <div class="fab-meme-footer">
              <div class="meme-stats">
                <div v-b-tooltip title="Views" class="meme-stats-item">
                  <div class="meme-stats-value">
                    <svg-icon name="view" class="mt-0" />
                    {{ numFormat(meme.views) }}
                  </div>
                </div>
                <div v-b-tooltip title="Upvotes" class="meme-stats-item">
                  <div class="meme-stats-value">
                    <svg-icon name="up" />
                    {{ numFormat(meme.ups) }}
                  </div>
                </div>
                <div v-b-tooltip title="Downvotes" class="meme-stats-item">
                  <div class="meme-stats-value">
                    <svg-icon name="down" class="mt-0" />
                    {{ numFormat(meme.downs) }}
                  </div>
                </div>
                <div v-b-tooltip title="Comments" class="meme-stats-item">
                  <div class="meme-stats-value">
                    <svg-icon name="comments" />
                    {{ numFormat(meme.commentCount) }}
                  </div>
                </div>
              </div>
            </div>
          </drag>
        </div>
        <div v-else-if="!$apollo.queries.memeSearch.loading && !hasMemes" class="text-center mt-4">
          Nothing found! <br />
          Try another keyword.
        </div>
        <div v-if="$apollo.queries.memeSearch.loading">
          <skeleton-screens class="images-grid skeleton" itemClass="meme-item" times="4" width="100%" height="250px" />
        </div>
      </div>
    </div>

    <b-modal
      id="FabViewMemeModal"
      size="lg"
      body-class="p-0"
      modal-class="persist-panel fab-view-meme-modal"
      content-class="border-0 overflow-hidden rounded"
      centered
      hide-header
      hide-footer
    >
      <template v-slot="{ hide }">
        <div class="modal-meme-details border-bottom py-2 px-3">
          <div class="d-flex justify-content-between">
            <h5 class="font-weight-bold" :title="viewMeme.title">{{ viewMeme.title }}</h5>

            <b-button variant="link" class="text-muted" @click="hide"> <svg-icon name="close"></svg-icon> </b-button>
          </div>
          <div class="modal-meme-stats d-flex">
            <div v-b-tooltip title="Views" class="mr-3">
              <svg-icon name="view" class="mt-0"></svg-icon>
              {{ numFormat(viewMeme.views) }}
            </div>
            <div v-b-tooltip title="Upvotes" class="mr-3">
              <svg-icon name="up"></svg-icon>
              {{ numFormat(viewMeme.ups) }}
            </div>
            <div v-b-tooltip title="Downvotes" class="mr-3">
              <svg-icon name="down"></svg-icon>
              {{ numFormat(viewMeme.downs) }}
            </div>
            <div v-b-tooltip title="Comments" class="">
              <svg-icon name="comments"></svg-icon>
              {{ numFormat(viewMeme.commentCount) }}
            </div>
          </div>
        </div>
        <template v-if="viewMeme.isAlbum">
          <div
            v-for="(image, i) in viewMeme.images"
            :key="image.id"
            class="img-wrap shadow-sm"
            :class="{ 'mb-3': i !== viewMeme.images.length - 1 }"
          >
            <div
              v-if="viewMeme.type === 'image' || viewMeme.type === 'gif'"
              class="modal-img"
              :style="{ backgroundImage: `url(${image.url})` }"
            ></div>
            <VideoPlayer
              v-if="viewMeme.type === 'video'"
              :width="700"
              :height="500"
              :src="viewMeme.url"
              :autoplay="i === 0 ? 'any' : 'false'"
            />
          </div>
        </template>
        <template v-else>
          <div class="img-wrap">
            <div
              v-if="viewMeme.type === 'image' || viewMeme.type === 'gif'"
              class="modal-img"
              :style="{ backgroundImage: `url(${viewMeme.url})` }"
            ></div>
            <VideoPlayer
              v-if="viewMeme.type === 'video'"
              :width="700"
              :height="500"
              :src="viewMeme.url"
              autoplay="true"
            />
          </div>
        </template>
        <div class="border-top">
          <div class="text-right mt-3 mb-2 mr-2">
            <b-button @click="hide" size="sm" variant="link" class="text-muted px-4"> Close </b-button>
            <b-button variant="outline-secondary" size="sm" class="px-4" @click="shareActiveMeme">
              <svg-icon name="share" class="mr-2" />
              Insert Meme
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { Drag } from 'vue-drag-drop';

import { numFormat } from '~/utils/helpers';
import VideoPlayer from '~/components/VideoPlayer';
import { MEME_SEARCH_QUERY } from '~/graphql/queries';

import DragAndDropIndicator from '~/components/General/DragAndDropIndicator';

export default {
  name: 'editor-fabs-memes-panel',

  components: { Drag, DragAndDropIndicator, VideoPlayer },

  data() {
    return {
      memeSearch: [],
      memeKeywords: '',
      search: '',
      viewMeme: null,
    };
  },

  apollo: {
    memeSearch: {
      query: MEME_SEARCH_QUERY,
      variables: {
        query: '',
        limit: 10,
      },
    },
  },

  computed: {
    hasMemes() {
      return this.memeSearch && this.memeSearch.memes && this.memeSearch.memes.length > 0;
    },
  },

  mounted() {
    const panel = this.$refs.memesScroll;
    panel.addEventListener('scroll', this.checkMedia);
  },

  beforeDestroy() {
    const panel = this.$refs.memesScroll;
    panel.removeEventListener('scroll', this.checkMedia);
  },

  methods: {
    numFormat,

    checkMedia: debounce(function() {
      const panel = this.$refs.memesScroll;
      const videos = panel.querySelectorAll("video:not([autoplay='autoplay'])");
      const tolerancePixel = 300;

      // Get current container top and bottom
      const scrollTop = panel.scrollTop + tolerancePixel;
      const scrollBottom = panel.scrollTop + panel.offsetHeight - tolerancePixel;

      videos.forEach(function(video) {
        const container = video.closest('.meme-item');
        const yTopMedia = container.offsetTop;
        const yBottomMedia = container.offsetHeight + yTopMedia;
        let playPromise;

        if (scrollTop < yBottomMedia && scrollBottom > yTopMedia) {
          playPromise = video.play();

          if (playPromise !== undefined) {
            playPromise
              .then(() => {
                // Playback started!
              })
              .catch(() => {
                // Play was prevented
              });
          }
        } else {
          video.pause();
        }
      });
    }, 100),

    handleMemeClick(meme) {
      this.viewMeme = meme;

      this.$bvModal.show('FabViewMemeModal');
    },

    shareActiveMeme() {
      this.$emit('insert', this.viewMeme);

      this.$bvModal.hide('FabViewMemeModal');
    },

    handleDragStart() {
      this.$store.dispatch('postEditor/updateIsDragging', true);
    },

    handleDragEnd({ data }, e) {
      this.$emit('dragged', { data, x: e.x, y: e.y });
      this.$store.dispatch('postEditor/updateIsDragging', false);
    },

    handleMemeKeywordChange() {
      this.memeKeywords = this.search;
    },

    refetchMemes(event) {
      event.preventDefault();

      this.memeSearch.memes = [];

      this.$apollo.queries.memeSearch.refetch({
        query: this.memeKeywords,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.meme-panel {
  // background-color: aliceblue;
  min-height: 445px;

  .images-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .meme-item {
      width: 100%;
      margin-bottom: 15px;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
      border-radius: 10px;
      border: 1px solid $gray-100;
      position: relative;
      color: $primary;

      &.video {
        background-color: $gray-600;
      }
      &.image {
        @include skeleton-animation($gray-200);
      }

      .image-count {
        position: absolute;
        top: 15px;
        right: 15px;
      }

      .meme-img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 100%;
        height: 250px;
        background-size: cover;
        cursor: move;
      }
      .meme-video {
        width: 100%;
        min-height: 250px;
        max-height: 250px;
      }
      .meme-caption {
        background-color: $white;
        padding: 7px 10px;
      }

      .meme-type {
        position: absolute;
        top: 220px;
        right: 20px;
      }

      .fab-meme-footer {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 0 0 15px;
        background-color: $white;

        .meme-stats {
          display: flex;
          justify-content: space-between;
          border-top: 1px solid $gray-200;
          padding-top: 10px;

          .meme-stats-item {
            text-align: center;
            border-right: 1px solid $gray-200;
            width: 100%;

            &:last-of-type {
              border-right: 0;
            }

            .meme-stats-name {
              font-size: 0.7rem;
              margin-bottom: 2px;
            }
            .meme-stats-value {
              font-size: 0.9rem;
              font-weight: bold;
            }
          }
        }
      }
    }

    &.skeleton {
      .meme-img {
        cursor: default;
      }
    }
  }
}
.fab-view-meme-modal {
  .modal-dialog {
    max-width: 900px;
  }

  .img-wrap {
    max-height: 90vh;
    overflow: hidden;
    background-color: $primary;

    .modal-img {
      height: 90vh;
      width: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .view-meme-close-btn {
    position: absolute;
    right: -50px;

    .icon {
      fill: $white;
    }

    &:focus {
      box-shadow: none;
    }
  }
}
</style>
