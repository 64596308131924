import MediumEditor from 'medium-editor';

export default MediumEditor.extensions.anchorPreview.extend({
  positionPreview: function(activeAnchor) {
    activeAnchor = activeAnchor || this.activeAnchor;
    var buttonHeight = this.anchorPreview.offsetHeight,
      boundary = activeAnchor.getBoundingClientRect(),
      diffLeft = this.diffLeft,
      diffTop = this.diffTop,
      elementsContainer = this.getEditorOption('elementsContainer'),
      relativeBoundary = {},
      halfOffsetWidth,
      defaultLeft,
      middleBoundary,
      elementsContainerBoundary,
      top;

    halfOffsetWidth = this.anchorPreview.offsetWidth / 2;
    var toolbarExtension = this.base.getExtensionByName('toolbar');
    if (toolbarExtension) {
      diffLeft = toolbarExtension.diffLeft;
      diffTop = toolbarExtension.diffTop;
    }
    defaultLeft = diffLeft - halfOffsetWidth;

    elementsContainerBoundary = elementsContainer.getBoundingClientRect();
    ['top', 'left'].forEach(function(key) {
      relativeBoundary[key] = boundary[key] - elementsContainerBoundary[key];
    });

    relativeBoundary.width = boundary.width;
    relativeBoundary.height = boundary.height;
    boundary = relativeBoundary;
    // Adjust top position according to container scroll position
    top = elementsContainer.scrollTop;

    middleBoundary = boundary.left + boundary.width / 2;
    top += buttonHeight + boundary.top + boundary.height - diffTop - this.anchorPreview.offsetHeight;

    this.anchorPreview.style.top = Math.round(top) + 'px';
    this.anchorPreview.style.right = 'initial';

    this.anchorPreview.style.left = defaultLeft + middleBoundary + 'px';
    this.anchorPreview.style.right = 'initial';
  },
});
