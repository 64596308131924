<template>
  <div ref="quotesScroll" class="panel-scroll editor-fabs-quotes-panel">
    <div class="panel-body">
      <div class="panel-body-top">
        <h6 class="mb-3">Find Quotes</h6>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="search-addon1">
              <svg-icon name="search" />
            </span>
          </div>
          <input
            type="text"
            aria-label="search"
            aria-describedby="search-addon1"
            v-model="search"
            @keyup.enter="refetchQuotes"
            @change="handleQuoteKeywordChange"
            autocomplete="off"
            placeholder="Enter a keyword and hit enter"
          />
        </div>

        <div v-if="hasQuotes">
          <DragAndDropIndicator label="quotes" />
        </div>
      </div>

      <div class="mt-4">
        <div v-if="hasQuotes" class="">
          <drag
            v-for="quote in quoteSearch.quotes"
            :transfer-data="{ type: 'quote', data: quote }"
            :key="quote.id"
            class="quote-item"
            @dragstart="handleDragStart"
            @dragend="handleDragEnd"
          >
            <p class="mb-0">{{ quote.body }}</p>
            <div class="my-2 font-weight-bold text-right">- {{ quote.author }}</div>
          </drag>
        </div>
        <div v-else-if="!$apollo.queries.quoteSearch.loading && !hasQuotes" class="text-center mt-4">
          Nothing found! <br />
          Try another keyword.
        </div>
        <div v-if="$apollo.queries.quoteSearch.loading" class="text-center">
          <EditorFabsQuotesSkeleton />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Drag } from 'vue-drag-drop';
import { QUOTE_SEARCH_QUERY } from '~/graphql/queries';
import EditorFabsQuotesSkeleton from './EditorFabsQuotesSkeleton';

import DragAndDropIndicator from '~/components/General/DragAndDropIndicator';

export default {
  name: 'editor-fabs-quotes-panel',

  components: { DragAndDropIndicator, EditorFabsQuotesSkeleton, Drag },

  data() {
    return {
      quoteSearch: {},
      page: 1,
      quoteKeywords: '',
      search: '',
      apiRoot: process.env.VUE_APP_API_ROOT,
    };
  },

  apollo: {
    quoteSearch: {
      query: QUOTE_SEARCH_QUERY,
      variables() {
        return {
          keywords: this.quoteKeywords,
          page: this.page,
        };
      },
    },
  },

  computed: {
    hasQuotes() {
      return this.quoteSearch && this.quoteSearch.quotes && this.quoteSearch.quotes.length > 0;
    },
  },

  methods: {
    handleDragStart() {
      this.$store.dispatch('postEditor/updateIsDragging', true);
    },

    handleDragEnd({ data }, e) {
      this.$emit('dragged', { data, x: e.x, y: e.y });
      this.$store.dispatch('postEditor/updateIsDragging', false);
    },

    handleQuoteKeywordChange() {
      this.quoteKeywords = this.search;
    },

    refetchQuotes(event) {
      event.preventDefault();

      this.quoteSearch.quotes = [];

      this.$apollo.queries.quoteSearch.refetch({
        keywords: this.quoteKeywords,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.editor-fabs-quotes-panel {
  .quote-item {
    box-shadow: 1px 2px 5px $gray-200;
    padding: 10px;
    cursor: move;
    margin-bottom: 20px;
    border: 1px solid transparent;
    background-color: azure;
    color: $primary;

    &:hover {
      border: 1px solid $primary;
      border-radius: 4px;
    }
  }
}
</style>
