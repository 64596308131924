<template>
  <div ref="imagesScroll" class="panel-scroll images-panel">
    <div class="panel-body">
      <div class="images__panel-btn btn-group mx-auto mb-3">
        <b-button
          variant="clear"
          class=" w-100"
          :class="{ active: platform === 'ai-image' }"
          @click="setActivePlatform('ai-image')"
        >
          <img src="@/assets/images/logo/ai.png" height="20" alt="" />
          <span class="text">Use AI</span>
        </b-button>
        <b-button
          variant="clear"
          class="w-100"
          :class="{ active: platform === 'unsplash' }"
          @click="setActivePlatform('unsplash')"
        >
          <img src="@/assets/images/unsplash-logo-50.png" height="20" alt="" />
          <span class="text">Unsplash</span>
        </b-button>
        <b-button
          variant="clear"
          class="w-100"
          :class="{ active: platform === 'youzign' }"
          @click="setActivePlatform('youzign')"
        >
          <img src="@/assets/images/youzign-logo-50.png" height="20" alt="" />
          <span class="text">Youzign</span>
        </b-button>
      </div>

      <transition name="platform-slide">
        <AiImageGenerator v-if="platform === 'ai-image'" @dragged="$emit('dragged', $event)" />
        <UnsplashImages v-if="platform === 'unsplash'" @dragged="$emit('dragged', $event)" />
        <YouzignDesigns v-if="platform === 'youzign'" @dragged="$emit('dragged', $event)" />
      </transition>
    </div>
  </div>
</template>

<script>
import { STOCK_IMAGE_SEARCH_QUERY } from '~/graphql/queries';
import UnsplashImages from './UnsplashImages';
import YouzignDesigns from './YouzignDesigns';
import AiImageGenerator from '../Ai/AiImageGenerator';

export default {
  name: 'editor-fabs-images-panel',

  components: { UnsplashImages, YouzignDesigns, AiImageGenerator },

  data() {
    return {
      images: [],
      offset: 1,
      platform: 'ai-image',
      apiRoot: process.env.VUE_APP_API_ROOT,
    };
  },

  apollo: {
    images: {
      query: STOCK_IMAGE_SEARCH_QUERY,
      variables() {
        return {
          keywords: this.imageKeywords,
          offset: this.offset,
          search: '',
          limit: 50,
        };
      },
    },
  },

  computed: {
    hasImages() {
      return this.images && this.images.contents && this.images.contents.length > 0;
    },
  },

  methods: {
    setActivePlatform(platform) {
      this.platform = platform;
    },

    handleDragStart() {
      this.$store.dispatch('postEditor/updateIsDragging', true);
    },

    handleDragEnd({ data }, e) {
      this.$emit('dragged', { data, x: e.x, y: e.y });
      this.$store.dispatch('postEditor/updateIsDragging', false);
    },

    refetchImages(event) {
      event.preventDefault();

      this.images.contents = [];

      this.$apollo.queries.images.refetch({
        keywords: this.imageKeywords,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.images-panel {
  .images__panel {
    &-btn {
      display: flex;

      button {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        align-items: center;
        gap: 8px;
        background: transparent;
        border: 1px solid #b7e4c7;
        border-radius: 10px;
        width: 65px;
        height: 48px;
        color: #b7e4c7;
        .text {
          font-size: 10px;
        }

        img {
          height: 18px;
          border-radius: 5px;
        }
        &:hover,
        &.active {
          color: #fff;
          transition: all 0.2s linear !important;
          background: $success;
        }
      }
    }
  }
}
</style>
