<template>
  <div>
    <div v-for="item in items" :key="item + 1" class="shadow-sm p-3 mb-3">
      <skeleton-screen width="100%" class="mb-2" />
      <skeleton-screen width="98%" class="mb-2" />
      <skeleton-screen width="100%" class="mb-3" />
      <skeleton-screen width="150px" height="10px" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'editor-fabs-quotes-skeleton',

  props: {
    itemCount: {
      type: Number,
      default: 5,
    },
  },

  computed: {
    items() {
      return [...Array(this.itemCount).keys()];
    },
  },
};
</script>
