<template>
  <div class="pop">
    <div @click="toggleDropdown" class="selected-wrap">
      <span class="current-select-label">{{ selectedName }}</span>
      <span class="pop-arrow"><svg-icon name="chevron-down"/></span>
    </div>

    <div v-show="isDropdownOpen" class="combo" v-click-outside="closeDropdown">
      <ul class="select-list">
        <li
          v-for="(tag, i) in tags"
          :key="i + 1"
          @click="handleSelect(tag)"
          :class="{ selected: tag.name == selectedName }"
        >
          <div class="select-sample">{{ tag.name }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: String,
    },
  },

  data() {
    return {
      isDropdownOpen: true,
      tags: [
        {
          name: 'H1',
          tag: 'h1',
        },
        {
          name: 'H2',
          tag: 'h2',
        },
        {
          name: 'H3',
          tag: 'h3',
        },
        {
          name: 'H4',
          tag: 'h4',
        },
        {
          name: 'H5',
          tag: 'h5',
        },
        {
          name: 'H6',
          tag: 'h6',
        },
        {
          name: 'P',
          tag: 'p',
        },
      ],
    };
  },

  computed: {
    selectedName() {
      const selected = this.tags.filter(tag => {
        return tag.tag == this.selected;
      });

      return selected.length ? selected[0].name : '';
    },
  },

  updated() {
    if (this.isDropdownOpen) {
      // Scroll to the selected tag
      [...document.querySelectorAll('.select-list .select-sample')]
        .filter(label => label.textContent == this.selectedName)
        .forEach(label => {
          document.querySelector('.combo').scrollTop = label.parentNode.offsetTop;
        });
    }
  },

  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },

    closeDropdown(event) {
      const isClickOnSelected = event.target.closest('.selected-wrap');
      const isClickOnDropdown = event.target.closest('.combo');

      if (!isClickOnSelected && !isClickOnDropdown) {
        this.isDropdownOpen = false;
      }
    },

    handleSelect(tag) {
      this.isDropdownOpen = false;

      this.$emit('select', tag);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.heading-popup {
  padding: 19px 12px 17px 12px;
  margin: 0 11px;

  > .popup-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 3px 0;
    padding: 0;
    color: #2b5672;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    text-align: left;
    width: calc(100% - 20px);
  }

  > .pop {
    position: relative;
    cursor: pointer;

    > .selected-wrap {
      position: relative;
      box-sizing: border-box;
      color: #162d3d;
      font-size: 18px;
      height: 36px;
      line-height: 36px;
      min-height: 36px;
      padding: 0 30px 0 0;
      margin: 0;

      .current-select-label {
        font-size: 18px;
        padding-right: 30px;
        color: #162d3d;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        height: 36px;
        line-height: 36px;
        min-height: 36px;
        display: inline;
      }

      .pop-arrow {
        border: 2px solid $primary;
        border-radius: 50%;
        content: '';
        display: block;
        height: 22px;
        opacity: 0.6;
        right: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 22px;

        .icon {
          fill: $primary;
          left: 50%;
          position: absolute;
          top: 50%;
          margin-top: 0;
          transform: translate(-50%, -50%);
          height: 0.7em;
          width: 0.7em;
        }
      }
    }

    .combo {
      border-radius: 8px;
      box-shadow: 0 0 22px 0 rgba(22, 45, 61, 0.26);
      box-shadow: 0 0 18px 0 rgba(22, 45, 61, 0.27);
      position: fixed;
      width: 240px;
      bottom: 50px;
      left: 20px;
      background-color: $white;
      z-index: 100;
      max-height: 100%;
      overflow-y: auto;

      .select-list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          box-sizing: border-box;
          cursor: pointer;
          display: block;
          font-size: 1rem;
          height: 36px;
          line-height: 36px;
          padding: 0 22px;
          position: relative;

          &:first-child {
            border-radius: 8px 0 0 0;
          }
          &:last-child {
            border-radius: 0 0 0 8px;
          }

          &:hover {
            background-color: rgba($primary, 0.3);
          }
          &.selected {
            background-color: $primary;
            color: $white;
          }
        }
      }
    }
  }
}
</style>
