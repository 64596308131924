<template>
  <div class="unsplash-wrap">
    <div v-if="!workspace.youzignProfile" class="connect-youzign-wrap">
      <p>You have not connected a Youzign account.</p>
      <b-button
        :to="{ name: 'settings.integrations', hash: '#youzign' }"
        size="sm"
        class="shadow font-weight-bold px-4 mt-2"
      >
        Connect Youzign
      </b-button>
    </div>
    <template v-else>
      <div class="panel-body-top" v-if="hasDesigns">
        <h6 class="mb-3 mt-2">My Youzign Designs</h6>
        <div>
          <DragAndDropIndicator label="designs" />
        </div>
      </div>

      <div class="designs-wrap mt-4">
        <div v-if="hasDesigns" class="designs-grid">
          <drag
            v-for="design in designs"
            :key="design.id"
            :transfer-data="{ type: 'image', platform: 'youzign', data: design }"
            class="design-item shadow-sm"
            @dragstart="handleDragStart"
            @dragend="handleDragEnd"
            @click.native="viewDesign(design)"
          >
            <div :style="{ backgroundImage: `url(${design.thumb})` }" class="design-img"></div>
          </drag>
        </div>
        <div v-else-if="!queryLoading && !hasDesigns" class="text-center mt-4">
          <p>Error loading designs</p>
        </div>
        <div v-if="queryLoading">
          <skeleton-screens
            class="designs-grid skeleton"
            itemClass="design-item"
            times="10"
            width="145px"
            height="145px"
          />
        </div>
      </div>
    </template>

    <b-modal
      id="ViewYouzignDesignModal"
      size="lg"
      body-class="p-0"
      modal-class="fabs-view-image-modal persist-panel"
      content-class="border-0 rounded-0"
      centered
      hide-header
      hide-footer
    >
      <template v-slot="{ hide }">
        <b-button @click="hide" variant="clear" class="fabs-design-close-btn"><svg-icon name="close"/></b-button>
        <img v-if="viewingDesign" :src="viewingDesign.imageUrl" class="w-100" alt="" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Drag } from 'vue-drag-drop';
import { mapState } from 'vuex';

import { YOUZIGN_DESIGNS_QUERY } from '~/graphql/queries';

import DragAndDropIndicator from '~/components/General/DragAndDropIndicator';

export default {
  name: 'editor-fabs-designs-panel',

  components: { Drag, DragAndDropIndicator },

  data() {
    return {
      designs: [],
      queryLoading: false,
      platform: 'unsplash',

      viewingDesign: null,
    };
  },

  computed: {
    hasDesigns() {
      return this.designs && this.designs.length > 0;
    },
    ...mapState({
      workspace: state => state.workspace.current,
    }),
  },

  created() {
    if (this.workspace.youzignProfile) {
      this.queryLoading = true;
      this.$apollo
        .query({
          query: YOUZIGN_DESIGNS_QUERY,
          variables: {
            workspace: this.workspace.id,
            page: 1,
          },
        })
        .then(({ data }) => {
          this.queryLoading = false;
          this.designs = data.youzignDesigns;
        })
        .catch(() => {
          this.queryLoading = false;
          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    }
  },

  methods: {
    setActivePlatform(platform) {
      this.platform = platform;
    },

    viewDesign(design) {
      this.viewingDesign = design;

      this.$bvModal.show('ViewYouzignDesignModal');
    },

    handleDragStart() {
      this.$store.dispatch('postEditor/updateIsDragging', true);
    },

    handleDragEnd({ data }, e) {
      this.$emit('dragged', { data, x: e.x, y: e.y });
      this.$store.dispatch('postEditor/updateIsDragging', false);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.images-panel {
  .designs-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .design-item {
      width: 145px;
      height: 145px;
      margin-bottom: 15px;
      @include skeleton-animation($gray-200);

      .design-img {
        width: 145px;
        height: 145px;
        background-size: cover;
        cursor: move;
      }
    }

    &.skeleton {
      .design-img {
        cursor: default;
      }
    }
  }

  .connect-youzign-wrap {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
  }
}
.fabs-view-image-modal {
  img {
    min-height: 500px;
    @include skeleton-animation($gray-400);
  }
}
.fabs-design-close-btn {
  position: absolute;
  right: -100px;

  .icon {
    fill: $white;
  }

  &:focus {
    box-shadow: none;
  }
}
</style>
