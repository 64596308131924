import MediumEditor from 'medium-editor';

export default MediumEditor.extensions.toolbar.extend({
  init: function() {
    MediumEditor.Extension.prototype.init.apply(this, arguments);

    this.initThrottledMethods();

    this.attachEventHandlers();
  },

  getToolbarElement: function() {
    return this.document.getElementById('toolbar');
  },

  // Hiding/showing toolbar

  isDisplayed: function() {
    if (this.getToolbarElement()) {
      return this.getToolbarElement().classList.contains('editor-toolbar-active');
    }
  },

  showToolbar: function() {
    clearTimeout(this.hideTimeout);
    if (!this.isDisplayed()) {
      this.getToolbarElement().classList.add('editor-toolbar-active');
    }
    this.trigger('showToolbar', {}, this.base.getFocusedElement());
  },

  hideToolbar: function() {
    if (this.isDisplayed()) {
      this.getToolbarElement().classList.remove('editor-toolbar-active');
    }
    this.trigger('hideToolbar', {}, this.base.getFocusedElement());
  },

  // Updating the toolbar

  showAndUpdateToolbar: function() {
    this.modifySelection();
    this.setToolbarButtonStates();
    this.trigger('positionToolbar', {}, this.base.getFocusedElement());
    // this.showToolbarDefaultActions();
    this.setToolbarPosition();
  },

  setToolbarButtonStates: function() {
    this.trigger('updateButtonStates', {}, this.base.getFocusedElement());
  },

  positionToolbar: function() {
    var toolbarElement = this.getToolbarElement(),
      positions = {};

    positions.top = '150px';
    positions.left = 'initial';
    positions.right = '20px';

    ['top', 'left', 'right'].forEach(function(key) {
      toolbarElement.style[key] = positions[key] + (isNaN(positions[key]) ? '' : 'px');
    });
  },
});
