<template>
  <div class="unsplash-wrap">
    <div class="panel-body-top">
      <h6 class="mb-3 heading-text">
        Search
        <a href="https://unsplash.com/?utm_source=Contentburger&utm_medium=referral" target="_blank"> Unsplash</a>
        Images
      </h6>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <div class="d-inline-flex align-items-center justify-content-center px-2" id="search-addon1">
            <svg-icon name="search" />
          </div>
        </div>
        <input
          type="text"
          aria-label="search"
          aria-describedby="search-addon1"
          @keyup.enter="refetchImages"
          @input="handleImageKeywordChange"
          autocomplete="off"
          placeholder="Enter a keyword and hit enter"
        />
      </div>

      <div v-if="hasImages">
        <DragAndDropIndicator label="images" />
      </div>
    </div>

    <div class="images-wrap mt-4">
      <div v-if="hasImages" class="images-grid">
        <drag
          v-for="image in images.contents"
          :key="image.id"
          :transfer-data="{ type: 'image', platform: 'unsplash', data: image }"
          class="image-item"
          @dragstart="handleDragStart"
          @dragend="handleDragEnd"
        >
          <div :style="{ backgroundImage: `url(${image.thumb})` }" class="image-img">
            <div class="image-author-wrap">
              <span>
                By <a :href="image.authorUrl" :title="image.authorName" target="_blank">{{ image.authorName }}</a>
              </span>
            </div>
          </div>
        </drag>
      </div>
      <div v-else-if="!$apollo.queries.images.loading && !hasImages" class="text-center mt-4">
        Nothing found! <br />
        Try another keyword.
      </div>
      <div v-if="$apollo.queries.images.loading">
        <skeleton-screens class="images-grid skeleton" itemClass="image-item" times="4" width="145px" height="145px" />
      </div>
    </div>
  </div>
</template>

<script>
import { Drag } from 'vue-drag-drop';

import { STOCK_IMAGE_SEARCH_QUERY } from '~/graphql/queries';
import DragAndDropIndicator from '~/components/General/DragAndDropIndicator';
import { debounce } from 'lodash';

export default {
  name: 'editor-fabs-images-panel',

  components: { Drag, DragAndDropIndicator },

  data() {
    return {
      images: [],
      offset: 1,
      imageKeywords: '',
      platform: 'unsplash',

      apiRoot: process.env.VUE_APP_API_ROOT,
    };
  },

  apollo: {
    images: {
      query: STOCK_IMAGE_SEARCH_QUERY,
      variables() {
        return {
          keywords: this.imageKeywords,
          offset: this.offset,
          limit: 10,
        };
      },
    },
  },

  computed: {
    hasImages() {
      return this.images && this.images.contents && this.images.contents.length > 0;
    },
  },

  created() {
    this.handleImageKeywordChange = debounce(
      input => {
        if (input.target.value.length === 0) {
          return;
        }
        console.log('val', input.target.value);
        this.imageKeywords = input.target.value;
      },
      400,
      {
        maxWait: 1000,
        trailing: true,
      },
    );
  },

  methods: {
    setActivePlatform(platform) {
      this.platform = platform;
    },

    handleDragStart() {
      this.$store.dispatch('postEditor/updateIsDragging', true);
    },

    handleDragEnd({ data }, e) {
      this.$emit('dragged', { data, x: e.x, y: e.y });
      this.$store.dispatch('postEditor/updateIsDragging', false);
    },

    refetchImages(event) {
      event.preventDefault();

      this.images.contents = [];
      this.$apollo.queries.images.refetch({
        keywords: this.imageKeywords,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.images-panel {
  .heading-text {
    a {
      color: #d8f3dc;
      text-decoration: underline;
    }
  }
  .images-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .image-item {
      width: calc(50% - (2 * 5px));
      height: 145px;
      margin-bottom: 15px;
      border-radius: 10px;
      @include skeleton-animation($gray-200);

      .image-img {
        width: 100%;
        height: 145px;
        margin-bottom: 15px;
        border-radius: 10px;
        background-size: cover;
        cursor: move;
        position: relative;
        overflow: hidden;

        .image-author-wrap {
          background-color: rgba($primary, 0.7);
          position: absolute;
          bottom: -30px;
          width: 100%;
          padding: 2px 5px;
          transition: all 0.3s ease-out;

          span {
            color: $white;
            font-size: 0.8rem;
            line-height: 1.2;
            width: 100%;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            a {
              color: $white;
            }
          }
        }
      }

      &:hover {
        .image-author-wrap {
          bottom: 0;
        }
      }
    }

    &.skeleton {
      .image-img {
        cursor: default;
      }
    }
  }
}
</style>
