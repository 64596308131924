<template>
  <div ref="gifsScroll" class="panel-scroll gif-panel">
    <div class="panel-body">
      <div class="panel-body-top">
        <h6 class="mb-3">Search Gifs</h6>
        <!-- <b-form-input
        @keyup.enter="refetchGifs"
        @change="handleGifKeywordChange"
        class="bg-light shadow-sm border-0"
        size="sm"
        autocomplete="off"
        placeholder="Enter a keyword and hit enter"
      /> -->
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="search-addon1">
              <svg-icon name="search" />
            </span>
          </div>
          <input
            type="text"
            aria-label="search"
            aria-describedby="search-addon1"
            v-model="search"
            @keyup.enter="refetchGifs"
            @change="handleGifKeywordChange"
            autocomplete="off"
            placeholder="Enter a keyword and hit enter"
          />
        </div>

        <div v-if="hasGifs">
          <DragAndDropIndicator label="Gifs" />
        </div>
      </div>

      <div class="images-wrap mt-4">
        <h6>Trending</h6>
        <div v-if="hasGifs" class="images-grid">
          <drag
            v-for="gif in gifSearch.gifs"
            :key="gif.id"
            :transfer-data="{ type: 'gif', data: gif }"
            class="image-item"
            @dragstart="handleDragStart"
            @dragend="handleDragEnd"
          >
            <div :style="{ backgroundImage: `url(${gif.imageUrl})` }" class="image-img"></div>
          </drag>
        </div>
        <div v-else-if="!$apollo.queries.gifSearch.loading && !hasGifs" class="text-center mt-4">
          Nothing found! <br />
          Try another keyword.
        </div>
        <div v-if="$apollo.queries.gifSearch.loading">
          <skeleton-screens
            class="images-grid skeleton"
            itemClass="image-item"
            times="4"
            width="145px"
            height="145px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Drag } from 'vue-drag-drop';
import { GIF_SEARCH_QUERY } from '~/graphql/queries';

import DragAndDropIndicator from '~/components/General/DragAndDropIndicator';

export default {
  name: 'editor-fabs-gifs-panel',

  components: { Drag, DragAndDropIndicator },

  data() {
    return {
      gifSearch: [],
      offset: 1,
      gifKeywords: '',
      search: '',
      apiRoot: process.env.VUE_APP_API_ROOT,
    };
  },

  apollo: {
    gifSearch: {
      query: GIF_SEARCH_QUERY,
      variables() {
        return {
          keywords: this.computedGifKeywords,
          offset: this.offset,
          search: '',
          limit: 20,
        };
      },
    },
  },

  computed: {
    computedGifKeywords() {
      return this.gifKeywords || 'random';
    },
    hasGifs() {
      return this.gifSearch && this.gifSearch.gifs && this.gifSearch.gifs.length > 0;
    },
  },

  methods: {
    handleDragStart() {
      this.$store.dispatch('postEditor/updateIsDragging', true);
    },

    handleDragEnd({ data }, e) {
      this.$emit('dragged', { data, x: e.x, y: e.y });
      this.$store.dispatch('postEditor/updateIsDragging', false);
    },

    handleGifKeywordChange() {
      this.gifKeywords = this.search;
    },

    refetchGifs(event) {
      event.preventDefault();

      this.gifSearch.gifs = [];

      this.$apollo.queries.gifSearch.refetch({
        keywords: this.computedGifKeywords,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.gif-panel {
  .images-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .image-item {
      width: 145px;
      height: 145px;
      margin-bottom: 15px;
      border-radius: 10px;
      @include skeleton-animation($gray-200);

      .image-img {
        width: 145px;
        height: 145px;
        margin-bottom: 15px;
        border-radius: 10px;
        background-size: cover;
        cursor: move;
      }
    }

    &.skeleton {
      .image-img {
        cursor: default;
      }
    }
  }
}
</style>
