<template>
  <div class="drag-drop-indicator-wrap">
    <!-- <div class="drag-drop-dash"></div> -->
    <div class="drag-drop-dash drag-drop-box">Drag and drop {{ label }} in editor</div>
    <svg-icon name="drag" size="md" class="drag-drop-icon" />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.drag-drop-indicator-wrap {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;

  // .drag-drop-dash {
  //   border-radius: 5px;
  //   border: 2px dashed $primary;
  //   width: 100%;
  //   height: 40px;
  //   position: relative;
  //   z-index: 1;
  // }

  .drag-drop-box {
    text-align: center;
    color: $gray-300;
    font-size: 0.8rem;
    background-color: $primary;
    border: 2px dashed #d8f3dc;
    border-radius: 5px;
    width: 94%;
    margin: 2px auto;
    height: 40px;
    padding: 10px 40px 10px 10px;
    z-index: 2;
    &:hover {
      box-shadow: 0px 0px 5px $gray-100;
      // transform: rotate(-2deg);
      .drag-drop-icon {
        transform: rotate(-2deg);
      }
    }
  }
  .drag-drop-icon {
    position: absolute;
    top: 10px;
    right: 25px;
  }
}
</style>
