<template>
  <div ref="uploadsScroll" class="panel-scroll uploads-panel">
    <div class="panel-body">
      <h6 class="mt-2 mb-3">My Uploads</h6>

      <div @click="$refs.ImageUpload.click()" class="dropzone-wrap" :class="{ disabled: newUpload.type }">
        <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
          <path d="M12 12L12 6 11 6 11 12 5 12 5 13 11 13 11 19 12 19 12 13 18 13 18 12z" />
        </svg>
        <div class="dropzone-label">Add an Image {{ allowVideos ? 'or Video' : '' }}</div>
        <input
          ref="ImageUpload"
          :accept="allowedFileTypes.join(',')"
          @change="handleImageUpload"
          type="file"
          style="display: none"
        />
      </div>

      <div class="mt-4 images-wrap">
        <div v-if="newUpload.type" class="p-3 my-3 rounded shadow-sm d-flex align-items-center uploading-wrap">
          <template v-if="newUpload.type === 'video'">
            <div class="mr-2"><b-spinner variant="secondary" type="grow" /></div>
            <svg-icon name="film" class="video-icon" />
            <div class="text-truncate">{{ newUpload.name }}</div>
          </template>
          <template v-else-if="newUpload.type === 'image'">
            <div class="mr-2"><b-spinner variant="secondary" type="grow" /></div>
            <div :style="{ backgroundImage: `url(${newUpload.url})` }" class="new-image"></div>
            <div class="text-truncate">{{ newUpload.name }}</div>
          </template>
        </div>

        <DragAndDropIndicator v-if="images.length || (allowVideos && videos.length)" label="uploads" />

        <div v-if="images.length">
          <h6 class="pb-1 border-bottom">Images</h6>
          <div class="images-grid user-uploads">
            <drag
              v-for="uploadImage in images"
              :key="uploadImage.id"
              :transfer-data="{ type: 'upload', data: uploadImage }"
              class="image-item"
              @click.native="handleClick(uploadImage)"
              @dragstart="handleDragStart"
              @dragend="handleDragEnd"
            >
              <div :style="{ backgroundImage: `url(${uploadImage.thumb})` }" class="image-img"></div>
            </drag>
          </div>
        </div>

        <div v-if="allowVideos && videos.length">
          <h6 class="pb-1 border-bottom">Videos</h6>
          <div class="videos-grid">
            <drag
              v-for="video in videos"
              :key="video.id"
              :transfer-data="{ type: 'video', data: video }"
              class="video-item"
              @click.native="handleClick(video)"
              @dragstart="handleDragStart"
              @dragend="handleDragEnd"
            >
              <div :style="{ backgroundImage: `url(${video.thumb})` }" class="video-img">
                <div v-if="video.status === 'processing'" class="busy-state">
                  <b-spinner variant="secondary" small />
                </div>
                <div v-else class="video-indicator"><video-icon /></div>
              </div>
            </drag>
          </div>
        </div>

        <div v-if="$apollo.queries.userUploads.loading">
          <skeleton-screens class="images-grid skeleton" itemClass="image-item" times="9" width="90px" height="90px" />
        </div>
        <div class="p-5 text-center shadow-sm w-100" v-if="!hasUploads && !$apollo.queries.userUploads.loading"><h5>No uploads yet</h5></div>
      </div>
    </div>

    <b-modal
      id="ViewUploadModal"
      size="md"
      body-class="p-0"
      modal-class="persist-panel view-upload-modal"
      content-class="overflow-hidden border-0 rounded"
      centered
      hide-header
      hide-footer
    >
      <template v-slot="{ hide }">
        <div v-if="isDeleting" class="py-5 text-center"><b-spinner /></div>
        <div v-else class="">
          <div v-if="activeUpload.type === 'image'" class="img-wrap">
            <LazyImage :src="activeUpload.url" :initialHeight="500" class="w-100" alt="" />
          </div>
          <div v-else-if="activeUpload.type === 'video'">
            <VideoPlayer :width="700" :height="400" :src="activeUpload.url" />
          </div>
          <div class="px-3 py-2 modal-video-details">
            <div class="d-flex align-items-center justify-content-between">
              <div class="">
                <b-button @click="deleteActiveUpload" size="sm" variant="link" class="persist-panel text-danger">
                  <svg-icon name="trash" class="mr-1" />
                  Delete
                </b-button>
              </div>
              <div class="">
                <b-button @click="hide()" size="sm" variant="link" class="px-4 text-muted"> Close </b-button>
                <b-button @click="handleInsertActiveUpload" size="sm" variant="outline-primary" class="px-4">
                  <svg-icon name="plus" class="mr-1" />
                  Insert {{ activeUpload.type }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Drag } from 'vue-drag-drop';

import { USER_UPLOADS_QUERY } from '~/graphql/queries';
import { SAVE_USER_UPLOAD_MUTATION, DELETE_USER_UPLOAD_MUTATION } from '~/graphql/mutations';

import InfiniteScrollMixin from '~/mixins/InfiniteScrollMixin';

import LazyImage from '~/components/LazyImage';
import VideoPlayer from '~/components/VideoPlayer';
import DragAndDropIndicator from '~/components/General/DragAndDropIndicator';

export default {
  name: 'editor-fabs-uploads-panel',

  mixins: [InfiniteScrollMixin],

  components: { Drag, DragAndDropIndicator, LazyImage, VideoPlayer },

  props: {
    allowVideos: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      userUploads: {},
      newUpload: {},
      offset: 1,
      limit: 10,
      imageKeywords: '',
      search: '',

      apiRoot: process.env.VUE_APP_API_ROOT,

      imageFormats: ['image/png', 'image/jpeg', 'image/gif', 'image/bmp', 'image/svg', 'image/webp'],
      videoFormats: ['video/mp4', 'video/avi', 'video/mpeg', 'video/quicktime', 'video/x-matroska'],

      activeUpload: {},
      isDeleting: false,
    };
  },

  apollo: {
    userUploads: {
      query: USER_UPLOADS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          limit: this.limit,
        };
      },
    },
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),

    allowedFileTypes() {
      return this.allowVideos ? this.imageFormats.concat(this.videoFormats) : this.imageFormats;
    },
    computedImageKeywords() {
      return this.imageKeywords || 'random';
    },
    uploads() {
      return this.userUploads.uploads || [];
    },
    hasUploads() {
      return this.uploads.length > 0;
    },
    images() {
      return this.uploads.filter(upload => upload.type === 'image');
    },
    videos() {
      return this.uploads.filter(upload => upload.type === 'video');
    },
  },

  watch: {
    isRockBottom(isRockBottom) {
      if (
        isRockBottom &&
        this.userUploads &&
        this.userUploads.uploads &&
        this.userUploads.uploads.length &&
        this.loadMoreEnabled &&
        !this.loadingMore
      ) {
        this.loadMoreUploads();
      }
    },
  },

  mounted() {
    this.$refs.uploadsScroll.addEventListener('scroll', this.scrollEventCallback);

    window.Echo.channel('videos').listen('VideoProcessed', video => {
      if (this.$apollo.queries.userUploads && !this.$apollo.queries.userUploads.loading) {
        this.updateUpload(video.id, {
          status: 'ready',
          path: video.path,
        });

        this.$notify({
          group: 'main',
          type: 'native',
          title: 'Video process completed',
          text: 'Video has been processed and is ready to go.',
        });
      }
    });

    window.Echo.channel('videos').listen('VideoProcessingFailed', video => {
      if (this.$apollo.queries.userUploads && !this.$apollo.queries.userUploads.loading) {
        const data = this.readQueryData();
        const index = data.userUploads.uploads.findIndex(a => video.id === a.id);

        if (index !== -1) {
          data.userUploads.uploads.splice(index, 1);
        }
        this.writeQueryData(data);

        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Video processing failed',
          text: 'An error occurred while processing your video.',
        });
      }
    });
  },

  beforeDestroy() {
    this.$refs.uploadsScroll.removeEventListener('scroll', this.scrollEventCallback);

    window.Echo.leave('videos');
  },

  methods: {
    updateUpload(videoId, updates) {
      const data = this.readQueryData();

      const index = data.userUploads.uploads.findIndex(a => videoId === a.id);
      if (index !== -1) {
        data.userUploads.uploads.splice(index, 1, {
          ...data.userUploads.uploads[index],
          ...updates,
        });

        this.writeQueryData(data);
      }
    },

    readQueryData() {
      const store = this.$apollo.getClient();
      // Read the data from our cache for this query.
      return store.readQuery({
        query: USER_UPLOADS_QUERY,
        variables: {
          workspace: this.workspace.id,
          limit: this.limit,
        },
      });
    },

    writeQueryData(data) {
      const store = this.$apollo.getClient();
      store.writeQuery({
        query: USER_UPLOADS_QUERY,
        variables: {
          workspace: this.workspace.id,
          limit: this.limit,
        },
        data,
      });
    },

    scrollEventCallback() {
      this.isRockBottom = this.isBottomOf(this.$refs.uploadsScroll);
    },

    handleClick(upload) {
      if (upload.status === 'processing') return;

      this.activeUpload = upload;

      this.$bvModal.show('ViewUploadModal');
    },

    handleDragStart() {
      this.$store.dispatch('postEditor/updateIsDragging', true);
    },

    handleDragEnd({ data }, e) {
      this.$emit('dragged', { data: { ...data, imageUrl: data.url }, x: e.x, y: e.y });
      this.$store.dispatch('postEditor/updateIsDragging', false);
    },

    handleInsertActiveUpload() {
      this.$emit('insert', this.activeUpload);

      this.$bvModal.hide('ViewUploadModal');
    },

    handleImageKeywordChange(search) {
      this.imageKeywords = this.search;
    },

    refetchUploads(event) {
      event.preventDefault();

      this.loadMoreEnabled = true;
      this.userUploads.uploads = [];

      this.$apollo.queries.userUploads.refetch({
        keywords: this.computedImageKeywords,
      });
    },

    async loadMoreUploads() {
      this.offset = this.offset + 1;
      this.loadingMore = true;

      try {
        // Fetch more data and transform the original result
        await this.$apollo.queries.userUploads.fetchMore({
          // New variables
          variables: {
            workspace: this.workspace.id,
            offset: this.offset,
            limit: this.limit,
          },
          // Transform the previous result with new data
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newContents = fetchMoreResult.userUploads.uploads;
            const filtered = newContents.filter(img => {
              const existingIds = this.userUploads.uploads.map(exImg => exImg.id);
              return !existingIds.includes(img.id);
            });

            if (filtered.length < 1) {
              this.loadMoreEnabled = false;
              return {
                ...previousResult,
              };
            }

            const hasMore = fetchMoreResult.userUploads.hasMore;
            const total = fetchMoreResult.userUploads.total;

            this.loadMoreEnabled = hasMore;

            return {
              userUploads: {
                __typename: previousResult.userUploads.__typename,
                // Merging to the list
                uploads: [...previousResult.userUploads.uploads, ...filtered],
                total,
                hasMore,
              },
            };
          },
        });
      } catch (e) {
        console.warn(e.message);
      }

      this.loadingMore = false;
    },

    handleImageUpload(event) {
      const file = event.target.files[0];

      if (!this.isValidFile(file)) return;

      if (this.imageFormats.includes(file.type)) {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = async fileLoadedEvent => {
          const srcData = fileLoadedEvent.target.result;

          this.newUpload = {
            type: 'image',
            url: srcData,
            name: file.name,
          };
        };
      } else if (this.videoFormats.includes(file.type)) {
        this.newUpload = {
          type: 'video',
          name: file.name,
        };
      }

      this.$apollo
        .mutate({
          mutation: SAVE_USER_UPLOAD_MUTATION,
          variables: { workspace: this.workspace.id, upload: file },
          update: (store, { data: { saveUserUpload } }) => {
            // Read the data from our cache for this query.
            const data = this.readQueryData();
            // const data = store.readQuery({
            //   query: USER_UPLOADS_QUERY,
            //   variables: {
            //     workspace: this.workspace.id,
            //     limit: this.limit,
            //   },
            // });

            data.userUploads.uploads.unshift(saveUserUpload);

            // Write our data back to the cache.
            this.writeQueryData(data);
            // store.writeQuery({
            //   query: USER_UPLOADS_QUERY,
            //   variables: {
            //     workspace: this.workspace.id,
            //     limit: this.limit,
            //   },
            //   data,
            // });
          },
        })
        .then(({ data: { saveUserUpload } }) => {
          this.newUpload = {};

          if (saveUserUpload.status === 'processing') {
            this.$notify({
              group: 'main',
              type: 'native',
              title: 'Upload is being processed',
              text: 'This may take a moment... Consider grabbing a cup of tea.',
            });
          }
        })
        .catch(error => {
          const validations = error.graphQLErrors.filter(err => err.message == 'validation');

          if (validations.length) {
            validations.forEach(err => {
              const errors = err.extensions.validation;
              for (let key in errors) {
                const fieldErrors = errors[key];

                this.$notify({
                  group: 'main',
                  type: 'error',
                  title: `Invalid ${key}`,
                  text: fieldErrors.join('<br />'),
                });
              }
            });
          } else {
            this.$notify({
              group: 'main',
              type: 'error',
              title: 'Unable to upload',
              text: 'An error occurred while processing your upload.',
            });
          }

          this.newUpload = {};
        });
    },

    isValidFile(file) {
      const inValidType = this.allowVideos
        ? !this.imageFormats.includes(file.type) && !this.videoFormats.includes(file.type)
        : !this.imageFormats.includes(file.type);

      if (inValidType) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Invalid file type',
          text: `You can only upload an image${this.allowVideos ? ' or a video' : ''}`,
        });
        return false;
      }

      const size = file.size / 1000;
      if (this.imageFormats.includes(file.type) && size > 51200) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Image too large',
          text: 'Image size must not exceed 50MB',
        });
        return false;
      }

      if (this.allowVideos && this.videoFormats.includes(file.type) && size > 103600) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Video too large',
          text: 'Video size must not exceed 100MB',
        });
        return false;
      }

      return true;
    },

    deleteActiveUpload() {
      this.isDeleting = true;

      this.$apollo
        .mutate({
          mutation: DELETE_USER_UPLOAD_MUTATION,
          variables: { workspace: this.workspace.id, ids: [this.activeUpload.id] },
          update: (store, { data: { deleted } }) => {
            // Read the data from our cache for this query.
            const data = this.readQueryData();

            // const data = store.readQuery({
            //   query: USER_UPLOADS_QUERY,
            //   variables: {
            //     limit: 10,
            //   },
            // });
            if (deleted) {
              const index = data.userUploads.uploads.findIndex(a => this.activeUpload.id === a.id);

              if (index !== -1) {
                data.userUploads.uploads.splice(index, 1);
              }
            }
            // Write our data back to the cache.
            this.writeQueryData(data);
            // store.writeQuery({
            //   query: USER_UPLOADS_QUERY,
            //   variables: {
            //     limit: 10,
            //   },
            //   data,
            // });
          },
        })
        .then(() => {
          this.isDeleting = false;
          this.activeUpload = {};

          this.$bvModal.hide('ViewUploadModal');
        })
        .catch(() => {
          this.isDeleting = false;
          this.activeUpload = {};

          this.$bvModal.hide('ViewUploadModal');

          this.$notify({
            group: 'main',
            type: 'error',
            title: 'Upload could not be deleted',
            text: 'An error occurred while processing your request.',
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.uploads-panel {
  .dropzone-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 67px;
    border: 1px dashed rgba($secondary, 0.4);
    cursor: pointer;
    font-size: 14px;
    color: $secondary;
    margin: 5px;
    margin-top: 20px;

    &:hover {
      background-color: rgba($secondary, 0.2);
    }

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
    }

    .dropzone-label {
      line-height: 24px;
      text-align: center;
    }
  }

  .images-grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -25px;

    .image-item {
      width: 145px;
      height: 145px;
      margin-bottom: 15px;
      margin-right: 10px;
      @include skeleton-animation($gray-200);

      .image-img {
        width: 145px;
        height: 145px;
        background-size: cover;
        cursor: pointer;
      }
    }

    &.user-uploads {
      .image-item {
        width: 90px;
        height: 90px;

        .image-img {
          width: 90px;
          height: 90px;
        }
      }
    }

    &.skeleton {
      .image-img {
        cursor: move;
      }
    }
  }

  .videos-grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -25px;

    .video-item {
      width: 90px;
      height: 90px;
      margin-bottom: 15px;
      margin-right: 10px;
      @include skeleton-animation($gray-200);

      .video-img {
        display: flex;
        width: 90px;
        height: 90px;
        background-size: cover;
        cursor: pointer;

        .video-indicator {
          width: 100%;
          padding: 5px;
          align-self: flex-end;
          display: flex;
          align-items: flex-end;
          background-color: rgba($secondary, 0.2);

          .icon {
            fill: $white;
            margin-top: 0;
          }
        }
        .busy-state {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba($secondary, 0.7);
          cursor: wait;
        }
      }
    }

    &.skeleton {
      .video-img {
        cursor: move;
      }
    }
  }

  .uploading-wrap {
    .new-image {
      width: 30px;
      height: 30px;
      background-size: cover;
      margin-right: 10px;
    }

    .video-icon {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      fill: $secondary;
    }
  }
}
.view-upload-modal {
  .modal-dialog {
    max-width: 700px;
  }
}
</style>
