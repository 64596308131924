<template>
  <div ref="videoScroll" class="panel-scroll">
    <div class="panel-body">
      <div class="panel-body-top">
        <h6 class="mb-3">Find Videos</h6>
        <b-form-select v-model="source" class="bg-light border-0 shadow-sm mb-2" size="sm">
          <option value="youtube">Youtube</option>
          <option value="dailymotion">Dailymotion</option>
        </b-form-select>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="search-addon1">
              <svg-icon name="search" />
            </span>
          </div>
          <input
            type="text"
            aria-label="search"
            aria-describedby="search-addon1"
            v-model="search"
            @keyup.enter="refetchArticles"
            @change="handleArticleKeywordChange"
            autocomplete="off"
            placeholder="Enter a keyword and hit enter"
          />
        </div>

        <div v-if="hasVideos">
          <DragAndDropIndicator label="videos" />
        </div>
      </div>

      <div class="mt-4">
        <div v-if="$apollo.queries.videoSearch.loading" class="text-center"><EditorFabsVideosSkeleton /></div>
        <div v-if="hasVideos" class="">
          <drag
            v-for="video in videoSearch.videos"
            :transfer-data="{ type: 'video-link', data: video }"
            :key="video.id"
            class="video-link-item"
            @click.native="viewVideo(video)"
            @dragstart="handleDragStart"
            @dragend="handleDragEnd"
          >
            <div v-if="video.thumb" class="video-img" :style="{ backgroundImage: `url(${video.thumb})` }"></div>
            <div class="font-weight-bold text-truncate mb-2" :title="video.title">{{ video.title }}</div>
            <div v-if="source != 'youtube'" class="video-link-item__footer">
              <div class="d-flex text-center justify-content-between">
                <div class="w-100">
                  <div class="font-weight-bold">{{ numFormat(video.viewCount) }}</div>
                  <div class="text-muted small">Views</div>
                </div>
                <div class="w-100">
                  <div class="font-weight-bold">{{ numFormat(video.commentCount) }}</div>
                  <div class="text-muted small">Comments</div>
                </div>
                <div class="w-100">
                  <div class="font-weight-bold">{{ numFormat(video.likeCount) }}</div>
                  <div class="text-muted small">Likes</div>
                </div>
              </div>
            </div>
          </drag>
        </div>
        <div v-else-if="errored" class="text-center mt-5">
          Could not load videos at this time. <br />
          Try again later.
        </div>
        <div v-else-if="!$apollo.queries.videoSearch.loading && !hasVideos && !errored" class="text-center mt-5">
          Nothing found! <br />
          Try another keyword.
        </div>
      </div>
    </div>

    <b-modal
      id="ViewEditorFabsVideo"
      size="md"
      body-class="p-0"
      modal-class="persist-panel view-editor-fabs-video-modal"
      content-class="border-0 overflow-hidden rounded"
      centered
      hide-header
      hide-footer
    >
      <template v-slot="{ hide }">
        <div v-if="loadingDetails" class="p-5 m-5 text-center"><spinner /></div>
        <template v-else>
          <div v-html="activeVideo.embedHtml" class="video-embed-wrap"></div>
          <div class="modal-video-details py-2 px-3">
            <div class="d-flex justify-content-between">
              <h5 class="w-50 text-truncate" :title="activeVideo.title">{{ activeVideo.title }}</h5>
              <div class="modal-video-stats d-flex">
                <div class="w-100 mr-3">
                  <div class="font-weight-bold">{{ numFormat(activeVideo.viewCount) }}</div>
                  <div class="text-muted small">Views</div>
                </div>
                <div class="w-100 mr-3">
                  <div class="font-weight-bold">{{ numFormat(activeVideo.commentCount) }}</div>
                  <div class="text-muted small">Comments</div>
                </div>
                <div class="w-100">
                  <div class="font-weight-bold">{{ numFormat(activeVideo.likeCount) }}</div>
                  <div class="text-muted small">Likes</div>
                </div>
              </div>
            </div>

            <div class="text-right mt-3 mb-2 mr-2">
              <b-button @click="hide()" size="sm" variant="link" class="text-muted px-4"> Close </b-button>
              <b-button @click="handleInsertActiveVideo" variant="outline-secondary" size="sm" class="px-4">
                <svg-icon name="share" class="mr-2" />
                Insert Video
              </b-button>
            </div>
          </div>
        </template>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Drag } from 'vue-drag-drop';
import { numFormat } from '~/utils/helpers';
import EditorFabsVideosSkeleton from './EditorFabsVideosSkeleton';

import DragAndDropIndicator from '~/components/General/DragAndDropIndicator';

import { VIDEO_SEARCH_QUERY, YOUTUBE_VIDEO_DETAILS_QUERY } from '~/graphql/queries';

export default {
  name: 'editor-fabs-videos-panel',

  components: { Drag, DragAndDropIndicator, EditorFabsVideosSkeleton },

  data() {
    return {
      videoSearch: {},
      keywords: '',
      search: '',
      source: 'youtube',
      limit: 20,
      activeVideo: {},
      errored: false,
      loadingDetails: false,
    };
  },

  apollo: {
    videoSearch: {
      query: VIDEO_SEARCH_QUERY,
      variables() {
        return {
          keywords: [this.keywords],
          source: this.source,
          limit: this.limit,
        };
      },
      result({ data, loading }) {
        if (!loading && data.videoSearch) {
          this.errored = false;
        }
      },
      error() {
        this.errored = true;

        return true;
      },
    },
  },

  computed: {
    hasVideos() {
      return this.videoSearch && this.videoSearch.videos && this.videoSearch.videos.length > 0;
    },
  },

  methods: {
    viewVideo(video) {
      if (this.source === 'youtube') {
        this.loadingDetails = true;
        this.$bvModal.show('ViewEditorFabsVideo');

        this.$apollo
          .query({
            query: YOUTUBE_VIDEO_DETAILS_QUERY,
            variables: {
              id: video.id,
            },
          })
          .then(({ data }) => {
            this.loadingDetails = false;

            this.activeVideo = data.youtubeVideoDetails;
          })
          .catch(() => {
            this.$bvModal.hide('ViewEditorFabsVideo');

            this.$notify({
              group: 'main',
              type: 'native-error',
              text: 'Unable to load video. Please try again later or try another video.',
            });
          });
      } else {
        this.activeVideo = video;

        this.$bvModal.show('ViewEditorFabsVideo');
      }
    },

    handleInsertActiveVideo() {
      this.$emit('insert', this.activeVideo);

      this.$bvModal.hide('ViewEditorFabsVideo');
    },

    handleDragStart() {
      this.$store.dispatch('postEditor/updateIsDragging', true);
    },

    handleDragEnd({ data }, e) {
      this.$emit('dragged', { data, x: e.x, y: e.y });
      this.$store.dispatch('postEditor/updateIsDragging', false);
    },

    handleArticleKeywordChange() {
      this.keywords = this.search;
    },

    refetchVideos(event) {
      event.preventDefault();

      this.videoSearch.videos = [];

      this.$apollo.queries.videoSearch.refetch({
        keywords: this.keywords,
        source: this.source,
        limit: this.limit,
      });
    },

    numFormat,
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.video-link-item {
  box-shadow: 1px 2px 5px $gray-200;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 20px;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid $secondary;
    border-radius: 4px;
  }

  .video-excerpt {
    .highlighted {
      display: inline-block;
      background-color: rgba($secondary, 0.4);
      padding: 0 2px;
    }
  }

  .video-img {
    height: 150px;
    background-size: cover;
    border: 1px solid $gray-200;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .video-link-item__footer {
    border-top: 1px dashed $gray-200;
    padding: 0.5rem 0;
  }
}
.view-editor-fabs-video-modal {
  .video-embed-wrap {
    height: 400px;
    background-color: $primary;

    iframe {
      width: 100%;
      min-height: 400px;
    }
  }

  .modal-dialog {
    max-width: 700px;
  }

  .modal-video-stats {
    width: 40%;
  }
}
</style>
