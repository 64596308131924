<template>
  <div ref="RichTextEditor" class="RichTextEditor">
    <template v-if="showFabs">
      <EditorFabs
        @insertVideoLink="handleInsertVideoLink"
        @draggedImage="handleDraggedImage"
        @draggedQuote="handleDraggedQuote"
        @draggedMeme="handleDraggedMeme"
        @insertMeme="handleInsertMeme"
        @draggedVideo="handleDraggedVideo"
        @draggedArticle="handleDraggedArticle"
      />
    </template>

    <div class="editor-container" :class="{ allowed: isDragging }">
      <div v-if="gettingArticle" class="text-center p-5"><spinner variant="primary" /></div>
      <div
        v-show="!gettingArticle"
        class="Medium-editor"
        id="editor"
        ref="EditorNode"
        v-html="initialContent"
        :disabled="disabled"
      ></div>
    </div>

    <div v-show="isToolbarVisible" id="toolbar" class="editor-toolbar">
      <div class="header">
        <span class="header-title">Text Settings</span>

        <button @click="hideToolbar" class="panel-header-button"><svg-icon name="close" /></button>
      </div>
      <div class="fancy-scroll">
        <div>
          <div class="settings-popup">
            <label class="popup-label">Fonts</label>

            <FontSelect :selected="selectedFont" @select="handleFontSelect" />
          </div>

          <hr class="divider" />

          <div class="font-size-slider">
            <label>Font size</label>

            <div class="d-flex align-items-center">
              <div class="slider-wrap"><b-form-input type="range" v-model="fontSize" min="1" max="7" step="1" /></div>
              <div class="font-size-input">
                <input type="text" maxlength="7" step="1" v-model="fontSize" readonly />
              </div>
            </div>
          </div>

          <hr class="divider" />

          <div class="button-row">
            <button
              @click="formatText('bold')"
              class="toolbar-btn"
              id="textBoldBtn"
              :class="{ active: activeButtons.bold }"
            >
              <svg-icon name="text-bold" />

              <b-tooltip triggers="hover" target="textBoldBtn" container="textBoldBtn">
                <strong>Bold</strong> (Ctr + B)
              </b-tooltip>
            </button>

            <button
              @click="formatText('italic')"
              class="toolbar-btn"
              id="textItalicBtn"
              :class="{ active: activeButtons.italic }"
            >
              <svg-icon name="text-italic" />

              <b-tooltip triggers="hover" target="textItalicBtn" container="textItalicBtn">
                <strong>Italic</strong> (Ctr + I)
              </b-tooltip>
            </button>

            <button
              @click="formatText('underline')"
              class="toolbar-btn"
              id="textUnderlineBtn"
              :class="{ active: activeButtons.underline }"
            >
              <svg-icon name="text-underline" />

              <b-tooltip triggers="hover" target="textUnderlineBtn" container="textUnderlineBtn">
                <strong>Underline</strong> (Ctr + U)
              </b-tooltip>
            </button>

            <button @click="openTextColorPopover" class="toolbar-btn" id="textColorPopoverTrigger">
              <text-color :currentColor="currentTextColor" />

              <b-tooltip
                triggers="hover"
                v-if="!isShowTextColorPopover"
                target="textColorPopoverTrigger"
                container="textColorPopoverTrigger"
                >Color</b-tooltip
              >

              <b-popover container="toolbar" :show.sync="isShowTextColorPopover" target="textColorPopoverTrigger">
                <div class="d-flex justify-content-between" slot="title">
                  Pick a color
                  <button @click="closeTextColorPopover" class="panel-header-button"><svg-icon name="close" /></button>
                </div>
                <ColorPicker v-model="textColor" />

                <div class="text-right mt-3">
                  <b-button @click="closeTextColorPopover" type="reset" variant="clear" size="sm">Close</b-button>
                </div>

                <input class="colorDeselect" type="text" ref="textColorDeselect" />
              </b-popover>
            </button>

            <button @click="openTextBackColorPopover" class="toolbar-btn" id="textBackColorPopoverTrigger">
              <text-background :currentColor="currentTextBackColor" />

              <b-tooltip
                triggers="hover"
                v-if="!isShowTextBackColorPopover"
                target="textBackColorPopoverTrigger"
                container="textBackColorPopoverTrigger"
                >Highlight</b-tooltip
              >

              <b-popover
                container="toolbar"
                :show.sync="isShowTextBackColorPopover"
                target="textBackColorPopoverTrigger"
              >
                <div class="d-flex justify-content-between" slot="title">
                  Pick a color
                  <button @click="closeTextBackColorPopover" class="panel-header-button">
                    <svg-icon name="close" />
                  </button>
                </div>
                <ColorPicker v-model="textBackColor" />

                <div class="text-right mt-3">
                  <b-button @click="closeTextBackColorPopover" type="reset" variant="clear" size="sm">Close</b-button>
                </div>

                <input class="colorDeselect" type="text" ref="textBackColorDeselect" />
              </b-popover>
            </button>

            <button
              @click="openLinkPopover"
              id="linkPopoverTrigger"
              class="toolbar-btn toolbar-btn-dropdown link"
              :class="{ active: activeButtons.link }"
            >
              <text-link />

              <b-tooltip
                v-if="!isShowLinkDropdown && !isShowLinkPopover"
                target="linkPopoverTrigger"
                container="linkPopoverTrigger"
                >Link</b-tooltip
              >

              <div
                v-show="isShowLinkDropdown"
                @click.stop
                class="custom-dropdown"
                v-click-outside="handleLinkDropdownClickOutside"
              >
                <div class="custom-dropdown-options">
                  <div class="custom-dropdown-list">
                    <li @click="handleUnlink"><text-unlink /></li>
                    <li @click="handleEditLink" class="selected"><text-link /></li>
                  </div>
                </div>
              </div>
            </button>

            <b-popover
              container="toolbar"
              :show.sync="isShowLinkPopover"
              target="linkPopoverTrigger"
              title="Where do you want to link to?"
              @show="shouldPreventLinkPopover"
            >
              <b-form @submit="handleSaveLink" @reset="closeLinkPopover">
                <div class="mt-2">
                  <label for="url">What's the web address (URL)?</label>
                  <b-form-input
                    v-model="urlForm.url"
                    id="url"
                    size="sm"
                    type="text"
                    placeholder="Paste it here"
                    required
                    @input="handleUrlChange"
                  />
                  <has-error :form="urlForm" field="url"></has-error>

                  <!-- <b-form-group label="How does it open?" class="mt-3">
                    <b-form-radio :value="true" v-model="urlForm.isOpenInNewTab" name="newtab">New Window</b-form-radio>
                    <b-form-radio :value="false" v-model="urlForm.isOpenInNewTab" name="newtab"
                      >Current Window</b-form-radio
                    >
                  </b-form-group> -->
                </div>

                <div class="text-right mt-3">
                  <b-button type="reset" variant="clear" size="sm" class="mr-2">Cancel</b-button>
                  <b-button type="submit" variant="primary" size="sm" class="px-4">Save</b-button>
                </div>
              </b-form>
            </b-popover>
          </div>

          <hr class="divider" />

          <div class="button-row">
            <button @click="openEmojiPopover" class="toolbar-btn" id="insertEmoji">
              <smile />

              <b-tooltip v-if="!isShowEmojiPopover" triggers="hover" target="insertEmoji" container="insertEmoji"
                >Insert Emoji</b-tooltip
              >
            </button>

            <div id="emojiPopover">
              <b-popover
                container="emojiPopover"
                class="emoji-popover"
                :show.sync="isShowEmojiPopover"
                target="insertEmoji"
              >
                <div @click.stop class="d-flex justify-content-between" slot="title">
                  Pick an Emoji
                  <button @click="closeEmojiPopover" class="panel-header-button"><svg-icon name="close" /></button>
                </div>
                <div @click.stop><EmojiList @select="handleSelectEmoji" /></div>

                <div class="text-right border-top p-2">
                  <b-button @click="closeEmojiPopover" type="reset" variant="clear" size="sm">Close</b-button>
                </div>
              </b-popover>
            </div>

            <button
              @click="quoteText"
              class="toolbar-btn"
              :class="{ active: activeButtons.blockquote }"
              id="insertQuote"
            >
              <quote />

              <b-tooltip triggers="hover" target="insertQuote" container="insertQuote">Quote</b-tooltip>
            </button>

            <button @click="openAlignDropdown" class="toolbar-btn toolbar-btn-dropdown align" id="textAlignBtn">
              <div class="drop">
                <div class="drop-selected">
                  <text-align-left v-if="activeButtons.justifyLeft" />
                  <text-align-center v-else-if="activeButtons.justifyCenter" />
                  <text-align-right v-else-if="activeButtons.justifyRight" />
                  <text-align-full v-else-if="activeButtons.justifyFull" />
                </div>
                <span class="drop-arrow"> <chevron-down /> </span>
              </div>

              <b-tooltip triggers="hover" v-if="!isShowAlignDropdown" target="textAlignBtn" container="textAlignBtn"
                >Alignment</b-tooltip
              >

              <div
                v-show="isShowAlignDropdown"
                @click.stop
                class="custom-dropdown"
                v-click-outside="handleAlignDropdownClickOutside"
              >
                <div class="custom-dropdown-options">
                  <div class="custom-dropdown-list">
                    <li @click="alignText('justifyLeft')" :class="{ selected: activeButtons.justifyLeft }">
                      <text-align-left />
                    </li>
                    <li @click="alignText('justifyCenter')" :class="{ selected: activeButtons.justifyCenter }">
                      <text-align-center />
                    </li>
                    <li @click="alignText('justifyRight')" :class="{ selected: activeButtons.justifyRight }">
                      <text-align-right />
                    </li>
                    <li @click="alignText('justifyFull')" :class="{ selected: activeButtons.justifyFull }">
                      <text-align-full />
                    </li>
                  </div>
                </div>
              </div>
            </button>
            <button @click="openListDropdown" class="toolbar-btn toolbar-btn-dropdown list" id="textListBtn">
              <div class="drop">
                <div class="drop-selected">
                  <text-list-numbers v-if="activeButtons.insertorderedlist" />
                  <text-list-bullets v-else />
                </div>
                <span class="drop-arrow"> <chevron-down /> </span>
              </div>

              <b-tooltip triggers="hover" v-if="!isShowListDropdown" target="textListBtn" container="textListBtn"
                >Bullets &amp; Numbering</b-tooltip
              >

              <div
                v-show="isShowListDropdown"
                @click.stop
                class="custom-dropdown"
                v-click-outside="handleListDropdownClickOutside"
              >
                <div class="custom-dropdown-options">
                  <div class="custom-dropdown-list">
                    <li
                      @click="applyList('removeList')"
                      :class="{ selected: !activeButtons.insertunorderedlist && !activeButtons.insertorderedlist }"
                    >
                      <text-list-none />
                    </li>
                    <li @click="applyList('insertorderedlist')" :class="{ selected: activeButtons.insertorderedlist }">
                      <text-list-numbers />
                    </li>
                    <li
                      @click="applyList('insertunorderedlist')"
                      :class="{ selected: activeButtons.insertunorderedlist }"
                    >
                      <text-list-bullets />
                    </li>
                  </div>
                </div>
              </div>
            </button>
            <button @click="formatText('indent')" class="toolbar-btn" id="textIndentBtn">
              <text-indent-left />

              <b-tooltip triggers="hover" target="textIndentBtn" container="textIndentBtn">Increase Indent</b-tooltip>
            </button>
            <button @click="formatText('outdent')" class="toolbar-btn" id="textOutdentBtn">
              <text-outdent-left />

              <b-tooltip triggers="hover" target="textOutdentBtn" container="textOutdentBtn">Decrease Indent</b-tooltip>
            </button>
          </div>

          <hr class="divider" />

          <div class="heading-popup">
            <label class="popup-label">Heading tag</label>

            <TagSelect :selected="selectedTag" @select="handleTagSelect" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fonts from '~/assets/fonts';
import WebFont from 'webfontloader';

import Form from 'vform';
import { decode } from 'he';
import { mapState } from 'vuex';
import { Sketch } from 'vue-color';
import MediumEditor from 'medium-editor';
import TagSelect from './components/TagSelect';
import FontSelect from './components/FontSelect';
import EmojiList from '~/components/EmojiList';
import EditorFabs from '~/components/EditorFabs';
import 'medium-editor/dist/css/medium-editor.css';
import 'medium-editor/dist/css/themes/beagle.css';
import ToolbarExtension from './extensions/ToolbarExtension';
import AnchorPreviewExtension from './extensions/AnchorPreviewExtension';

import { GET_ARTICLE_QUERY } from '~/graphql/queries';
import { staticPublicPrefix } from '~/utils/helpers.js';

export default {
  name: 'rich-text-editor',

  components: {
    EmojiList,
    EditorFabs,
    FontSelect,
    TagSelect,
    ColorPicker: Sketch,
  },

  editor: null,

  props: {
    name: {
      type: String,
    },

    content: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    showFabs: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      initialContent: this.content,
      isToolbarVisible: false,
      fontSize: '',
      selectedFont: '',
      urlForm: new Form({
        newAnchor: '',
        isOpenInNewTab: false,
      }),
      isShowLinkPopover: false,
      isShowLinkDropdown: false,
      activeButtons: {
        bold: false,
        italic: false,
        underline: false,
        link: false,
        justifyLeft: false,
        justifyCenter: false,
        justifyRight: false,
        justifyFull: false,
        insertunorderedlist: false,
        insertorderedlist: false,
        blockquote: false,
      },
      isLinkEdit: false,
      isShowAlignDropdown: false,
      isShowListDropdown: false,
      textColor: '#000',
      textBackColor: 'none',
      isShowTextColorPopover: false,
      isShowTextBackColorPopover: false,
      selectedTag: 'p',
      isShowEmojiPopover: false,

      gettingArticle: false,
    };
  },

  computed: {
    currentTextColor() {
      return typeof this.textColor === 'object' ? this.textColor.hex : this.textColor;
    },

    currentTextBackColor() {
      return typeof this.textBackColor === 'object' ? this.textBackColor.hex : this.textBackColor;
    },

    isColorPicking() {
      return this.isShowTextColorPopover || this.isShowTextBackColorPopover;
    },

    ...mapState({
      invokedMeme: state => state.richEditor.invokedMeme,
      isDragging: state => state.postEditor.isDragging,
      selectedContent: state => state.createPost.selectedContent,
    }),
  },

  watch: {
    async content(nextContent) {
      // innerHTML MUST not be performed if the text did not actually change.
      // otherwise, the caret position will be reset.
      await this.$nextTick();

      if (nextContent !== this.$refs.EditorNode.innerHTML) {
        this.editor.setContent(this.content, 0);
        this.$refs.EditorNode.innerHTML = this.content;
      }
    },

    fontSize(size) {
      this.editor.execAction('fontSize', { value: size });
    },

    textColor(color) {
      // Restore the selection saved in this.openTextColorPopover()
      // so the color change can be applied to it.
      this.isColorPicking && this.editor.restoreSelection();

      // Apply the color change
      this.editor.execAction('foreColor', { value: color.hex });

      // Deselect the highlighted text again by focusing on the
      // invisible input element so the user can preview the
      // just-applied color change.
      this.isColorPicking && this.$refs.textColorDeselect.focus();
    },

    textBackColor(color) {
      // Restore the selection saved in this.openTextBackColorPopover()
      // so the background color change can be applied to it.
      this.isColorPicking && this.editor.restoreSelection();

      // Apply the background color change
      this.editor.execAction('backColor', { value: color.hex });

      // Deselect the highlighted text again by focusing on the
      // invisible input element so the user can preview the
      // just-applied background color change.
      this.isColorPicking && this.$refs.textBackColorDeselect.focus();
    },
  },

  mounted() {
    this.editor = new MediumEditor('#editor', {
      elementsContainer: this.$refs.RichTextEditor,
      placeholder: {
        text: this.placeholder || '',
      },

      extensions: {
        toolbar: new ToolbarExtension(),
        anchorPreview: new AnchorPreviewExtension(),
      },
    });

    this.editor.subscribe('editableInput', event => {
      const value = event.target ? event.target.innerHTML : '';
      this.$emit('input', value);
    });

    this.editor.subscribe('showToolbar', () => {
      this.fontSize = document.queryCommandValue('fontSize');

      const fontValue = document.queryCommandValue('fontName');
      this.selectedFont = fontValue.split(',')[0].replace(/"'/g, '');

      this.textColor = document.queryCommandValue('foreColor');
      this.textBackColor = document.queryCommandValue('backColor');

      const range = MediumEditor.selection.getSelectionRange(document);
      const node = MediumEditor.util.getClosestBlockContainer(range.startContainer);
      this.selectedTag = node.nodeName.toLowerCase();

      this.showToolbar();
    });

    this.editor.subscribe('updateButtonStates', () => {
      this.checkActiveButtons();
    });

    this.editor.subscribe('hideToolbar', () => {
      this.hideToolbar();
    });

    if (this.invokedMeme) {
      this.handleInsertMeme(this.invokedMeme);
      this.$store.dispatch('richEditor/invokeMeme', null);
    }

    WebFont.load({
      google: {
        families: fonts.filter(font => font.type === 'google').map(font => font.fontFamily),
      },
      custom: {
        families: fonts.filter(font => font.type === 'custom').map(font => font.fontFamily),
        urls: [`${staticPublicPrefix}assets/fonts/faces.css`],
      },
      active: () => {
        // All fonts are loaded
      },
    });
  },

  methods: {
    checkActiveButtons() {
      const buttons = Object.keys(this.activeButtons);

      buttons.forEach(button => {
        if (button == 'link') {
          return (this.activeButtons[button] = this.isAnchorSelected());
        }

        if (button == 'blockquote') {
          return (this.activeButtons[button] = this.isBlockquoteSelected());
        }

        const state = this.editor.queryCommandState(button);
        this.activeButtons[button] = state;
      });
    },

    showToolbar() {
      this.isToolbarVisible = true;
    },

    hideToolbar() {
      this.isToolbarVisible = false;
    },

    handleFontSelect(font) {
      this.editor.execAction('fontName', { value: font.fontFamily });
      this.selectedFont = font.fontFamily;
    },

    handleTagSelect(tag) {
      this.editor.execAction('removeFormat');
      this.editor.execAction(`append-${tag.tag}`);
      this.selectedTag = tag.tag;
    },

    formatText(action) {
      this.editor.execAction(action);
    },

    alignText(action) {
      this.editor.execAction(action);

      this.closeAlignDropdown();
    },

    quoteText() {
      this.editor.execAction('removeFormat');
      this.editor.execAction('append-blockquote');
    },

    applyList(action) {
      if (action === 'removeList') {
        // To remove list we execute the currently applied
        // list type's command again
        if (this.activeButtons.insertorderedlist) {
          this.editor.execAction('insertorderedlist');
        } else if (this.activeButtons.insertunorderedlist) {
          this.editor.execAction('insertunorderedlist');
        }
      } else {
        this.editor.execAction(action);
      }

      this.closeListDropdown();
    },

    handleUrlChange(input) {
      if (this.urlForm.errors.has('url') && this.isValidUrl(input)) {
        this.urlForm.errors.clear('url');
      }
    },

    handleSaveLink(event) {
      event.preventDefault();

      const opts = this.getLinkFormOpts();

      if (!this.isValidUrl(opts.value)) {
        return this.urlForm.errors.set('url', 'Invalid web address');
      }

      this.editor.restoreSelection();
      this.editor.execAction('createLink', opts);
      this.editor.checkSelection();

      this.resetUrlForm();
      this.closeLinkPopover(event);
    },

    handleEditLink(event) {
      if (this.isAnchorSelected()) {
        const range = MediumEditor.selection.getSelectionRange(document);
        let link = range.startContainer.parentNode.href;
        let target = range.startContainer.parentNode.target;

        if (!link || !target) {
          link = range.endContainer.parentNode.href;
          target = range.endContainer.parentNode.target;
        }

        this.urlForm.url = link;
        this.urlForm.isOpenInNewTab = target == '_blank';

        this.isLinkEdit = true;

        this.closeLinkDropdown(event);
        this.openLinkPopover();
      }
    },

    handleUnlink(event) {
      if (this.isAnchorSelected()) {
        this.editor.execAction('unlink');

        this.closeLinkDropdown(event);
      }
    },

    getLinkFormOpts: function() {
      let opts = {
        value: this.formatLink(this.urlForm.url),
      };

      opts.target = '_blank';
      if (this.urlForm.isOpenInNewTab) {
        opts.target = '_blank';
      }

      return opts;
    },

    openTextColorPopover() {
      this.isShowTextColorPopover = true;

      // Save selection to enable us safely remove focus from it later
      this.editor.saveSelection();

      window.setTimeout(() => {
        // textColorDeselect is an invisible input element used
        // just for the purpose of temporary deselection...
        // When we focus() on an input element the current
        // highlighted text unhighlights allowing user to
        // preview color changes.
        this.$refs.textColorDeselect.focus();
      });
    },

    closeTextColorPopover() {
      this.isShowTextColorPopover = false;

      // Highlight selection again
      this.editor.restoreSelection();
    },

    openTextBackColorPopover() {
      this.isShowTextBackColorPopover = true;

      // Save selection to enable us safely remove focus from it later
      this.editor.saveSelection();

      window.setTimeout(() => {
        // See comments in this.openTextColorPopover() to learn
        // what textBackColorDeselect is and how its being used
        this.$refs.textBackColorDeselect.focus();
      });
    },

    closeTextBackColorPopover() {
      this.isShowTextBackColorPopover = false;

      // Highlight selection again
      this.editor.restoreSelection();
    },

    shouldPreventLinkPopover(event) {
      if (this.isAnchorSelected() && !this.isLinkEdit) {
        return event.preventDefault();
      }
    },

    openLinkPopover() {
      this.editor.saveSelection();

      if (this.isAnchorSelected() && !this.isLinkEdit) {
        return this.showLinkDropdown();
      }

      this.isShowLinkPopover = true;
    },

    closeLinkPopover(event) {
      event.preventDefault();

      this.editor.restoreSelection();
      this.isShowLinkPopover = false;
      this.isLinkEdit = false;
      this.resetUrlForm();
    },

    showLinkDropdown() {
      this.isShowLinkDropdown = true;
    },

    closeLinkDropdown(event) {
      event.stopPropagation();

      this.isShowLinkDropdown = false;
    },

    openEmojiPopover() {
      // Save selection to remember where the cursor is
      // because if the user uses the emoji search input
      // the cursor will then be pointer that input.
      // we don't want that
      this.editor.saveSelection();

      this.isShowEmojiPopover = true;
    },

    closeEmojiPopover() {
      this.isShowEmojiPopover = false;
    },

    handleSelectEmoji(emoji) {
      // Restore the selection saved in this.openEmojiPopover()
      this.editor.restoreSelection();
      MediumEditor.util.insertHTMLCommand(document, emoji);

      // Save selection again because the previously saved in
      // this.openEmojiPopover() will be outdated if the user
      // clicks to add another emoji
      this.editor.saveSelection();
    },

    openAlignDropdown() {
      this.isShowAlignDropdown = true;
    },

    closeAlignDropdown() {
      this.isShowAlignDropdown = false;
    },

    openListDropdown() {
      this.isShowListDropdown = true;
    },

    closeListDropdown() {
      this.isShowListDropdown = false;
    },

    handleLinkDropdownClickOutside(event) {
      const isClickOnButton = event.target.closest('.toolbar-btn-dropdown.link');
      const isClickOnDropdown = event.target.closest('.link .custom-dropdown');

      if (!isClickOnDropdown && !isClickOnButton) {
        this.closeLinkDropdown(event);
      }
    },

    handleAlignDropdownClickOutside(event) {
      const isClickOnButton = event.target.closest('.toolbar-btn-dropdown.align');
      const isClickOnDropdown = event.target.closest('.align .custom-dropdown.align');

      if (!isClickOnDropdown && !isClickOnButton) {
        this.closeAlignDropdown();
      }
    },

    handleListDropdownClickOutside(event) {
      const isClickOnButton = event.target.closest('.toolbar-btn-dropdown.list');
      const isClickOnDropdown = event.target.closest('.list .custom-dropdown');

      if (!isClickOnDropdown && !isClickOnButton) {
        this.closeListDropdown(event);
      }
    },

    isAnchorSelected() {
      return this.isTagSelected('a');
    },

    isBlockquoteSelected() {
      return this.isTagSelected('blockquote');
    },

    isTagSelected(tagName) {
      const range = MediumEditor.selection.getSelectionRange(document);
      return (
        range.startContainer.nodeName.toLowerCase() === tagName ||
        range.endContainer.nodeName.toLowerCase() === tagName ||
        MediumEditor.util.getClosestTag(MediumEditor.selection.getSelectedParentElement(range), tagName)
      );
    },

    resetUrlForm() {
      this.urlForm.errors.clear();
      this.urlForm.reset();
    },

    handleDraggedImage({ data, x, y }) {
      const node = document.elementFromPoint(x, y);
      const containerNode = this.$refs.EditorNode.parentNode;

      if (MediumEditor.util.isDescendant(containerNode, node, true)) {
        this.insertImage(node, data.imageUrl);
      }
    },

    handleDraggedArticle({ data: article, x, y }) {
      const node = document.elementFromPoint(x, y);
      const editorNode = this.$refs.EditorNode;
      const containerNode = this.$refs.EditorNode.parentNode;

      if (MediumEditor.util.isDescendant(containerNode, node, true)) {
        if (node === containerNode) {
          MediumEditor.selection.moveCursor(document, editorNode);
        }

        this.gettingArticle = true;

        this.$apollo
          .query({
            query: GET_ARTICLE_QUERY,
            variables: {
              id: article.id,
            },
          })
          .then(({ data }) => {
            this.gettingArticle = false;
            const articleText = data.getArticle;

            const credits = `<div>
              <br />
              <p><em>This Post was originally published on <a href="${article.url}">${article.domain}</a></em></p>
            </div>`;

            const postContent = `
              <img src="${article.image}" alt="" />
              <hr />
              ${articleText} ${credits}
            `;

            MediumEditor.util.insertHTMLCommand(document, postContent);

            this.$store.dispatch('createPost/updateSelectedContent', {
              ...article,
              body: postContent,
              title: this.descape(article.title),
              postLink: article.url,
            });
          })
          .catch(() => {
            this.gettingArticle = false;

            this.$notify({
              group: 'main',
              type: 'native-error',
              text: 'An error occurred while processing your request.',
            });
          });

        // move the cursor into the end
        editorNode.lastChild && MediumEditor.selection.moveCursor(document, editorNode.lastChild);
      }
    },

    descape(text) {
      return decode(text);
    },

    handleDraggedVideo({ data, x, y }) {
      const node = document.elementFromPoint(x, y);
      const editorNode = this.$refs.EditorNode;
      const containerNode = this.$refs.EditorNode.parentNode;

      if (MediumEditor.util.isDescendant(containerNode, node, true)) {
        const divNode = document.createElement('div');
        divNode.innerHTML = `
          <p style="text-align: center">${data.embedHtml}</p><br>
          <h3>${data.title}</h3>
          <p>${data.description}</p>
        `;
        if (node === containerNode) {
          MediumEditor.selection.moveCursor(document, editorNode);
          MediumEditor.util.insertHTMLCommand(document, `${divNode.outerHTML}<p><br /></p>`);
        } else {
          this.editor.selectElement(node);
          MediumEditor.util.insertHTMLCommand(document, `${divNode.outerHTML}<p><br /></p>`);
        }
      }
    },

    handleDraggedMeme({ data, x, y }) {
      if (data.type === 'video') {
        return this.$notify({
          group: 'main',
          type: 'native-error',
          text: 'Video not yet supported in blog editor',
        });
      }

      const node = document.elementFromPoint(x, y);
      const containerNode = this.$refs.EditorNode.parentNode;

      if (MediumEditor.util.isDescendant(containerNode, node, true)) {
        if (data.isAlbum) {
          const images = data.images.filter(i => i.type === 'image' || i.type === 'gif');
          images.forEach(image => {
            this.insertImage(node, image.url);
          });
        } else {
          this.insertImage(node, data.url);
        }
      }
    },

    handleInsertMeme(meme) {
      if (meme.type === 'video') {
        return this.$notify({
          group: 'main',
          type: 'native-error',
          text: 'Video not yet supported in blog editor',
        });
      }

      const editorNode = this.$refs.EditorNode;
      const figureNode = document.createElement('figure');

      const caption = document.createElement('figcaption');
      caption.innerHTML = meme.title;
      figureNode.appendChild(caption);

      if (meme.isAlbum) {
        const images = meme.images.filter(i => i.type === 'image' || i.type === 'gif');

        images.forEach(image => {
          const img = document.createElement('img');
          img.src = image.url;
          figureNode.appendChild(img);
        });
      } else {
        const img = document.createElement('img');
        img.src = meme.url;
        figureNode.appendChild(img);
      }

      MediumEditor.selection.moveCursor(document, editorNode);
      MediumEditor.util.insertHTMLCommand(document, `${figureNode.outerHTML}<p><br /></p>`);
    },

    insertImage(node, url) {
      const editorNode = this.$refs.EditorNode;
      const containerNode = this.$refs.EditorNode.parentNode;

      const imgNode = document.createElement('img');
      imgNode.src = url;

      if (node === containerNode) {
        MediumEditor.selection.moveCursor(document, editorNode);
        MediumEditor.util.insertHTMLCommand(document, `${imgNode.outerHTML}<p><br /></p>`);
      } else {
        this.editor.selectElement(node);
        const selection = document.getSelection();
        const range = selection.getRangeAt(0);
        range.selectNode(range.commonAncestorContainer);
        range.collapse();

        MediumEditor.util.insertHTMLCommand(document, `${imgNode.outerHTML}<p><br /></p>`);
      }
    },

    handleInsertVideoLink(video) {
      const editorNode = this.$refs.EditorNode;

      const divNode = document.createElement('div');
      divNode.innerHTML = `
        <p style="text-align: center">${video.embedHtml}</p><br>
        <h3>${video.title}</h3>
        <p>${video.description}</p>
      `;
      MediumEditor.selection.moveCursor(document, editorNode);
      MediumEditor.util.insertHTMLCommand(document, `${divNode.outerHTML}<p><br /></p>`);
    },

    handleDraggedQuote({ data, x, y }) {
      const node = document.elementFromPoint(x, y);
      const editorNode = this.$refs.EditorNode;
      const containerNode = this.$refs.EditorNode.parentNode;

      if (MediumEditor.util.isDescendant(containerNode, node, true)) {
        const quoteNode = document.createElement('blockquote');
        quoteNode.innerHTML = `${data.body}<br /><br />- ${data.author}`;

        if (node === containerNode) {
          MediumEditor.selection.moveCursor(document, editorNode);
          MediumEditor.util.insertHTMLCommand(document, `${quoteNode.outerHTML}<p><br /></p>`);
        } else {
          this.editor.selectElement(node);
          const selection = document.getSelection();
          const range = selection.getRangeAt(0);
          range.selectNode(range.commonAncestorContainer);
          range.collapse();

          MediumEditor.util.insertHTMLCommand(document, `${quoteNode.outerHTML}<p><br /></p>`);
        }
      }
    },

    isValidUrl(url) {
      // eslint-disable-next-line
      const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/i;

      return urlRegex.test(url);
    },

    formatLink: function(value) {
      // Matches any alphabetical characters followed by ://
      // Matches protocol relative "//"
      // Matches common external protocols "mailto:" "tel:" "maps:"
      // Matches relative hash link, begins with "#"
      // eslint-disable-next-line
      var urlSchemeRegex = /^([a-z]+:)?\/\/|^(mailto|tel|maps):|^\#/i,
        hasScheme = urlSchemeRegex.test(value),
        scheme = '',
        // telRegex is a regex for checking if the string is a telephone number
        // eslint-disable-next-line
        telRegex = /^\+?\s?\(?(?:\d\s?\-?\)?){3,20}$/,
        urlParts = value.match(/^(.*?)(?:\?(.*?))?(?:#(.*))?$/),
        path = urlParts[1],
        query = urlParts[2],
        fragment = urlParts[3];

      if (telRegex.test(value)) {
        return 'tel:' + value;
      }

      if (!hasScheme) {
        var host = path.split('/')[0];
        // if the host part of the path looks like a hostname
        if (host.match(/.+(\.|:).+/) || host === 'localhost') {
          scheme = 'http://';
        }
      }

      return (
        scheme +
        // Ensure path is encoded
        this.ensureEncodedUri(path) +
        // Ensure query is encoded
        (query === undefined ? '' : '?' + this.ensureEncodedQuery(query)) +
        // Include fragment unencoded as encodeUriComponent is too
        // heavy handed for the many characters allowed in a fragment
        (fragment === undefined ? '' : '#' + fragment)
      );
    },

    ensureEncodedUri: function(str) {
      return str === decodeURI(str) ? encodeURI(str) : str;
    },

    ensureEncodedUriComponent: function(str) {
      return str === decodeURIComponent(str) ? encodeURIComponent(str) : str;
    },

    ensureEncodedParam: function(param) {
      var split = param.split('='),
        key = split[0],
        val = split[1];

      return key + (val === undefined ? '' : '=' + this.ensureEncodedUriComponent(val));
    },

    ensureEncodedQuery: function(queryString) {
      return queryString
        .split('&')
        .map(this.ensureEncodedParam.bind(this))
        .join('&');
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';
@import '~@/scss/content-formating';

%toolbar-arrow {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  left: 50%;
  margin-left: -8px;
  position: absolute;
  width: 0;
}

@keyframes editor-toolbar-pop-upwards {
  0% {
    opacity: 0;
    transform: matrix(0.97, 0, 0, 1, 0, 12);
  }

  20% {
    opacity: 0.7;
    transform: matrix(0.99, 0, 0, 1, 0, 2);
  }

  40% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, -1);
  }

  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

.RichTextEditor {
  position: relative;

  .editor-container {
    height: 100%;
    min-height: 100vh;

    &.allowed {
      background-color: azure;
      border: 2px dashed darken($primary, 10);
    }
  }

  .Medium-editor {
    outline: 0;

    img {
      min-height: 200px;
      min-width: 200px;
      @include skeleton-animation(lightgray);
    }
  }

  .medium-editor-toolbar-anchor-preview {
    background: $white;
    border-radius: 5px;
    box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
  }

  .medium-editor-anchor-preview a {
    color: $primary;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      text-decoration: underline;
    }
  }

  .medium-toolbar-arrow-over:before {
    border-bottom-color: $white;
  }

  .medium-editor-placeholder:after {
    font-style: normal;
    color: $gray-500;
  }

  .colorDeselect {
    height: 0;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    width: 0;
    top: 0;
    position: absolute;
  }

  .panel-header-button {

    .icon {
      fill: #fff;
    }
  }

  .editor-toolbar {
    user-select: none;
    position: fixed;
    top: 100px;
    width: 270px;
    box-shadow: 0 0 18px 0 rgba(22, 45, 61, 0.27);
    border-radius: 10px;
    font-size: 16px;
    text-align: left;
    background-color: #fff;
    // Make it pop
    animation: editor-toolbar-pop-upwards 160ms forwards linear;

    .header {
      background-color: $primary;
      height: 54px;
      min-height: 54px;
      box-sizing: border-box;
      position: relative;
      width: 100%;
      display: flex;
      padding: 10px 20px;
      border-radius: 10px 10px 0 0;
      justify-content: space-between;
      align-items: center;

      .header-title {
        font-size: 16px;
        line-height: 54px;
        overflow: hidden;
        color: #fff;
        max-width: 75%;
        white-space: nowrap;
      }
    }

    .font-size-slider {
      margin: 17px 10px 7px 24px;

      label {
        font-size: 14px;
        display: block;
        margin-bottom: 5px;
        color: #2b5672;
        text-align: left;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .slider-wrap {
        width: 70%;
        margin-right: 10px;
        display: flex;
        align-items: center;

        &:hover {
          + .font-size-input {
            background-color: rgba($primary, 0.2);
          }
        }
      }

      .font-size-input {
        border-radius: 8px;
        border: 1px solid transparent;
        width: 65px;
        position: relative;
        text-align: left;
        display: inline-block;

        &:hover {
          background-color: rgba($primary, 0.2);
          cursor: pointer;
        }

        input {
          text-align: right;
          background-color: transparent;

          border: none;
          outline: none;
          text-align: center;
          font-size: 18px;
          height: 28px;
          line-height: 36px;
          padding: 0 7px;
          width: 100%;
          display: inline-block;
          color: #162d3d;
          cursor: pointer;

          &:focus {
            border: 1px solid $primary;
            border-radius: 8px;
          }
        }
      }
    }

    .button-row {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 19px 12px;

      .toolbar-btn {
        background-color: transparent;
        border: 0;
        position: relative;

        min-width: 30px;
        height: 30px;
        display: flex;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:focus {
          outline: 0;
        }

        &:hover {
          background-color: rgba($primary, 0.2);
        }

        > .icon {
          margin-top: 0;
        }

        .icon {
          fill: #2b5672;
        }

        &.active {
          background-color: $primary;

          > svg {
            fill: $white;
          }
        }

        .tooltip {
          .arrow::before {
            border-top-color: $white;
            z-index: 1070;
          }

          .tooltip-inner {
            background-color: $white;
            box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
            font-size: 14px;
            position: relative;
            color: #2b5672;
            border-radius: 8px;
            padding: 12px;
            white-space: nowrap;

            &:empty {
              display: none;
            }
          }
        }
      }

      .drop {
        display: flex;
        align-items: center;

        .drop-arrow {
          margin-left: 5px;
          display: flex;
          align-items: center;

          svg {
            width: 9px;
            fill: $primary;
          }
        }
      }
    }

    .popover-header {
      background-color: $primary;
      color: $white;
      $offset-border-width: calc(#{$border-radius-sm} - #{$popover-border-width});
      @include border-top-radius($offset-border-width);
    }

    #emojiPopover {
      .popover {
        max-width: initial;

        .popover-body {
          padding: 0;
          font-size: 1rem;
        }
      }
    }

    .vc-sketch {
      width: 220px;
      padding: 0;
      background: transparent;
      border-radius: 0;
      box-shadow: none;
    }
  }

  .custom-dropdown {
    z-index: 1000;

    .custom-dropdown-options {
      position: absolute;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 0 22px 0 rgba(22, 45, 61, 0.26);
      flex-direction: column;
      left: 0;
      top: -5px;
      overflow: hidden;

      .custom-dropdown-list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          padding: 4px 11px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          box-sizing: border-box;
          cursor: pointer;
          display: block;
          font-size: 14px;
          height: 36px;
          line-height: 36px;
          position: relative;
          display: flex;
          align-items: center;

          &:hover {
            background-color: rgba($primary, 0.3);
          }

          &.selected {
            background-color: $primary;

            svg {
              fill: $white;
            }
          }
        }
      }
    }
  }

  .toolbar-btn {
    &.align,
    &.list {
      .custom-dropdown-list {
        li {
          padding: 4px 14px;
        }
      }
    }
  }
}
</style>
